import React, { useContext } from "react";
import { Avatar, Stack } from "@mui/material";
import { Title, RegularText, SecondaryText } from "../../atoms/Typography";
import { WindowContext } from "../../contexts/WindowContext";

const TeamMember: React.FC<{
  name: string,
  role: string,
  desc: string,
  img: string,
  reverse: boolean
}> = ({
  name,
  role,
  desc,
  img,
  reverse
}) => {
  const { isPhoneDevice } = useContext(WindowContext);

  return (
    <Stack 
      direction={isPhoneDevice? 'column' : reverse? 'row-reverse' : 'row'}
      spacing={3}
    >
      <Avatar 
        src={img}
        sx={{ 
          width: 124, 
          height: 124,
          margin: isPhoneDevice? 'auto' : 'initial'
        }}
      />
      <Stack style={{ textAlign: isPhoneDevice? 'center' : reverse? 'right' : 'left'}}>
        <Title>
          {name.toUpperCase()}
        </Title>
        <SecondaryText>
          {role}
        </SecondaryText>
        <RegularText style={{ maxWidth: 400 }}> 
          {desc}
        </RegularText>
      </Stack>
    </Stack>
  );
}

export default TeamMember;