import React, { useContext } from "react";
import { Dialog, Drawer, Slide, Stack } from "@mui/material";
import Button from "../../atoms/inputs/Button";
import { RegularText, Title } from "../../atoms/Typography";
import { WindowContext } from "../../contexts/WindowContext";

const ConfirmPopup: React.FC<{
  isOpen: boolean,
  onConfirm: () => void,
  onCancel: () => void
}> = ({
  isOpen,
  onConfirm,
  onCancel,
}) => {
  const { isPhoneDevice } = useContext(WindowContext);
  
  return (
    <>
      { isPhoneDevice ? (
        <Drawer
          anchor='bottom'
          open={isOpen}
          onClose={onCancel}
          style={{ zIndex: 1300 }}
          PaperProps={{
            sx: {
              backgroundImage: 'linear-gradient(to bottom, #ffffff, #f0f0f8, #ebebf4, #f0f0f8, #ebebf4, #f0f0f8, #ffffff)'
            }
          }}
        >
          <ConfirmDeal 
            onConfirm={onConfirm} 
            onCancel={onCancel} 
            textAlign={'center'}
          />
        </Drawer>
      ) : (
        <Dialog 
          fullScreen={isPhoneDevice}
          open={isOpen}
          onClose={onCancel}
          TransitionComponent={Slide}
          PaperProps={{
            style: { borderRadius: 25, overflowX: 'hidden' }
          }}
        >
          <ConfirmDeal onConfirm={onConfirm} onCancel={onCancel}/>
        </Dialog>
      )}
    </>
  );
}

const ConfirmDeal = ({
  onConfirm,
  onCancel,
  textAlign
}: any) => {
  return (
    <Stack spacing={3} m={3} textAlign={textAlign}>
      <Title>
        Jetzt Deal einlösen?
      </Title>
      <RegularText>
        Wenn du diesen Deal jetzt einlöst, wird ein Buchungscode generiert. Der Deal ist dann für 1 Stunde gültig. Du kannst ihn danach erst in 24h erneut aktivieren.
      </RegularText>
      <Stack direction={'row'} spacing={3} justifyContent={'center'}>
        <Button 
          label={'Abbrechen'} 
          color={'secondary'} 
          onClick={onCancel} 
          width={'40%'}
        />
        <Button 
          label={'OK'} 
          onClick={onConfirm} 
          width={'40%'}
        />
      </Stack>
    </Stack>
  );
}

export default ConfirmPopup;