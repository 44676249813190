import React, { useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import FilePickerIcon from '../../atoms/inputs/FilePickerIcon';
import { AbsoluteBox, RelativeBox } from '../../atoms/layout/Boxes';
import { ContactContext } from "../../contexts/ContactContext";

const ProfilePhoto: React.FC = () => {
  const { contactPhoto, uploadContactPhoto } = useContext(ContactContext);

  return (
    <RelativeBox style={{ height: '175px', width: '175px', margin: 'auto', top: 50 }}>
      <Avatar 
        src={contactPhoto}
        style={{ 
          height: '175px', 
          width: '175px', 
          border: '6px solid white', 
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', 
          backgroundColor: '#e0e0e0' 
        }}
      />
      <AbsoluteBox style={{ bottom: 0, right: '0' }}>
        <FilePickerIcon onSelectFile={uploadContactPhoto}/>
      </AbsoluteBox>
    </RelativeBox>
  );
}

export default ProfilePhoto;