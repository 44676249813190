import React from "react";
import GoogleMapWrapper from "./GoogleMapWrapper";
import { MapBox } from "../../atoms/layout/Boxes";
import { Deal } from "../../services/DealS";

const DealMapPreview: React.FC<{
  deal: Deal;
}> = ({ deal }) => {
  const { lat, lng } = deal;
  if (!lat || !lng) {
    return null;
  }
  const mapCenter = { lat, lng };

  return (
    <MapBox small>
      <GoogleMapWrapper
        deals={[deal]}
        mapCenter={mapCenter}
        mapZoom={16}
        markerSize={64}
      />
    </MapBox>
  );
};

export default DealMapPreview;
