import React, { useEffect, useState, useContext } from 'react';
import { FileS } from '../../services/FileS';
import CarouselWrapper from '../carousel/CarouselWrapper';
import styled from 'styled-components';
import { Skeleton } from '@mui/material';
import { Image } from '../../atoms/Image';

const DealPicturesGallery = ({
  dealId,
  partnerId
}: any) => {
  const [pictures, setPictures] = useState(['/img/deal-placeholder.png']);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    FileS.getFilesPerDeal(partnerId, dealId).then(files => {
      if (files && files.length > 0) {
        setPictures(files.map(file => {
          return FileS.getDealImageUrl(dealId, partnerId, file.fileName);
        }));
      }
    }).finally(() => {
      setLoading(false);
    })
  }, []);

  return (
    <>
    { loading ? (
      <Skeleton variant="rectangular" height={225}/>
      ) : (
        <CarouselWrapper height={'225px'} auto infiniteLoop interval={3000} dotColor={'#f5f5f5'}>
          { pictures.map((picture, index) => {
            return (
              <Image height={225} key={index} src={picture} />
            );
          })}
        </CarouselWrapper>
    )}
    </>
  );
};

export default DealPicturesGallery;