import React, { useContext } from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { AbsoluteBox, FixedBox } from '../../atoms/layout/Boxes';
import { Avatar, Skeleton } from '@mui/material';
import { ContactContext } from '../../contexts/ContactContext';
import { AppUserContext } from '../../contexts/AppUserContext';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

const UserActionIcon: React.FC<{
  onClick: (open: boolean) => void,
  isUserMenuOpened: boolean
}> = ({
  onClick,
  isUserMenuOpened
}) => {
  const { contactPhoto } = useContext(ContactContext);
  const { isAuthenticated } = useContext(AppUserContext);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <FixedBox right='15px' top='15px'>
      { (isAuthenticated && contactPhoto === undefined)? (
        <Skeleton variant='circular' width={50} height={50}  style={{border: '4px solid #f5f5f5'}}/>
      ) : (
        <Avatar 
          src={isUserMenuOpened? undefined : contactPhoto}
          onClick={() => onClick(!isUserMenuOpened)}
          sx={{ 
            border: '4px solid #f5f5f5',
            width: 50,
            height: 50,
            backgroundColor: '#e0e0e0',
            boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px'
          }}
        >
          { !contactPhoto && !isUserMenuOpened && (
            <PersonRoundedIcon sx={{ fontSize: 32 }} />
          )}
          {isUserMenuOpened && (
            <KeyboardDoubleArrowUpIcon 
              sx={{ fontSize: 32 }} 
            />
          )}
        </Avatar>
      )}
    </FixedBox>
  );
}

export default UserActionIcon;