import React from "react";
import moment from 'moment';
import { Stack } from "@mui/material";

const TimeFrame = ({timeFrame}: any) => {
    if (!timeFrame) { return null; }

    function getDaysString() {
        let days = [];
        if (timeFrame.monday) { days.push("MO"); }
        if (timeFrame.tuesday) { days.push("DI"); }
        if (timeFrame.wednesday) { days.push("MI"); }
        if (timeFrame.thursday) { days.push("DO"); }
        if (timeFrame.friday) { days.push("FR"); }
        if (timeFrame.saturday) { days.push("SA"); }
        if (timeFrame.sunday) { days.push("SO"); }
        if (days.length === 7) { return "MO-SO" };

        if (
            timeFrame.monday &&
            timeFrame.tuesday &&
            timeFrame.wednesday &&
            timeFrame.thursday &&
            timeFrame.friday &&
            !timeFrame.saturday &&
            !timeFrame.sunday
        ) {
            return "MO-FR";
        }
        return days.join(", ")
    }
    let start = undefined;
    let hours = undefined;
    let minutes = undefined;
    if (timeFrame.startTime) {
        hours = timeFrame.startTime.substring(0, 2);
        minutes = timeFrame.startTime.substring(3, 5);
        start = new Date();
        start.setHours(hours);
        start.setMinutes(minutes);
    }


    let end;
    if (timeFrame.endTime) {
        hours = timeFrame.endTime.substring(0, 2);
        minutes = timeFrame.endTime.substring(3, 5);
        end = new Date();
        end.setHours(hours)
        end.setMinutes(minutes);
    }


    return (
            <div>
            {getDaysString() + ' '}
  

            <div style={{display: 'inline'}}>                
            {start && moment(start).format('HH:mm')}
                {(start && end) && <> - </>}
                {end && moment(end).format('HH:mm')}
            </div>
            </div>
    )
}

export default TimeFrame;