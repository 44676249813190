import {AbsoluteBox, RelativeBox} from "../../atoms/layout/Boxes";
import {ImageWithLabel} from "../../atoms/Image";
import Paragraph from "./Paragraph";
import {DataS} from "../../services/DataS";
import VideoBox from "../videoBox/VideoBox";
import React, {useContext} from "react";
import {WindowContext} from "../../contexts/WindowContext";
import {useWindowDimensions} from "../../hooks/DimensionsHook";
import {NavigateFunction, useNavigate} from "react-router-dom";

const WhyDesktop = () => {
    const { isDesktopDevice } = useContext(WindowContext);
    const { width } = useWindowDimensions();
    const boxSize = width * .6;
    const navigate: NavigateFunction = useNavigate();

    const nav = (href: string | undefined) => {
        if (href) { navigate(href) }
    }

    return (
        <RelativeBox height={boxSize * (isDesktopDevice? .4 : .6) * 3 + 'px'}>
            <AbsoluteBox left={-boxSize * .1 + 'px'}>
                <ImageWithLabel
                    position='35%'
                    label='Nightlife'
                    imgProps={{
                        src: 'img/categories/nightlife.jpg',
                        rounded: true,
                        height: boxSize * (isDesktopDevice? .4 : .6),
                        width: boxSize * (isDesktopDevice? .4 : .6),
                        withBorder: true,
                        borderWidth: isDesktopDevice? 10 : 7,
                    }}
                />
            </AbsoluteBox>
            <AbsoluteBox left={boxSize * (isDesktopDevice? .4 : .6) + 'px'}>
                <Paragraph title={DataS.OPTIONS[0].title} text={DataS.OPTIONS[0].description} buttonLabel={DataS.OPTIONS[0].buttonLabel} onClick={() => nav(DataS.OPTIONS[0].buttonHref)} align={'left'} />
            </AbsoluteBox>
            <AbsoluteBox left={boxSize * .1 + 'px'} top={boxSize * (isDesktopDevice? .35 : .5) + 'px'}>
                <ImageWithLabel
                    position='20%'
                    label='Café'
                    imgProps={{
                        src: 'img/categories/caffe.jpg',
                        rounded: true,
                        height: boxSize * (isDesktopDevice? .4 : .6),
                        width: boxSize * (isDesktopDevice? .4 : .6),
                        withBorder: true,
                        borderWidth: isDesktopDevice? 10 : 7
                    }}
                />
            </AbsoluteBox>
            <AbsoluteBox left={boxSize * (isDesktopDevice? .6 : .8) + 'px'} top={boxSize * (isDesktopDevice? .4 : .6) + 'px'}>
                <Paragraph title={DataS.OPTIONS[1].title} text={DataS.OPTIONS[1].description} buttonLabel={DataS.OPTIONS[1].buttonLabel} onClick={() => nav(DataS.OPTIONS[1].buttonHref)} align={'left'}/>
            </AbsoluteBox>
            <AbsoluteBox right={boxSize * .1 + 'px'} top={boxSize * 2.25 * (isDesktopDevice? .35 : .5) + 'px'}>
                <VideoBox src='mp4/landing-video.mp4' height={boxSize * (isDesktopDevice? .4 : .6)} rounded />
            </AbsoluteBox>
            <AbsoluteBox right={boxSize * (isDesktopDevice? .6 : .8) + 'px'} top={boxSize * (isDesktopDevice? .4 : .6) * 2 + 'px'}>
                <Paragraph title={DataS.OPTIONS[2].title} text={DataS.OPTIONS[2].description} buttonLabel={DataS.OPTIONS[2].buttonLabel} onClick={() => {}} align={'left'}/>
            </AbsoluteBox>
        </RelativeBox>
    )
}

export default WhyDesktop;