import React from "react";
import DiscountBadge from "../../atoms/shapes/DiscountBadge";
import { Deal } from "../../services/DealS";
import { AbsoluteBox, RelativeBox } from '../../atoms/layout/Boxes';
import { Image } from "../../atoms/Image";

const DealImage: React.FC<{
  deal: Deal,
  size: number,
  hideDiscountBadge?: boolean,
  selected?: boolean,
  src?: string,
  withBorder?: boolean
}> = ({
  deal,
  size,
  hideDiscountBadge,
  selected,
  src,
  withBorder
}) => {
  return (
    <RelativeBox style={{ width: size, height: size }}>
      <Image
        src={src || deal.image || '/img/deal-placeholder.png'}
        width={size}
        height={size}
        withBorder={selected || withBorder} 
        borderColor={withBorder? 'white' : '#edc662'}
        borderWidth={withBorder? 7 : undefined}
        rounded
      />
      { !hideDiscountBadge && (
        <AbsoluteBox style={{ bottom: -10, right: 0 }}>
          <DiscountBadge discount={deal.saveEuro} size={size/2.5} />
        </AbsoluteBox>
      )}
    </RelativeBox>
  );
}

export default DealImage;