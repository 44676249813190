import { JwtUtils } from "../utils/JwtUtils";

export const HttpS = {
    getResponse,
    post,
    postNonAuth,
    patch,
    del,
    exchange,
    get,
    put,
    putFile
};

function putFile(url: string, file : File) {
    let data = new FormData();
    data.append("file", file);

    return fetch(url, {
        method: 'PUT',
        headers: { ...JwtUtils.getAuthHeader(), 'Accept': 'application/json' },
        credentials: "same-origin",
        body: data
    }).then(resp => {
        return resp;
    }).catch(err => {
        throw err;
    })
}

function getResponse(url : string) {
    return fetch(url, {
        method: 'GET',
        headers: {...JwtUtils.getAuthHeader(), 'Accept': 'application/json'},
        credentials: "same-origin"
    }).then(resp => {
        if (resp.ok) {
            return resp;
        } else {
            console.error("Response not OK (url: " + url + ")");
        }
    }).catch(reason => {
        console.error("Failed to get data at " + url + " because of a network error");
    });
}

function get(url : string) {
    return fetch(url, {
        method: 'GET',
        headers: {...JwtUtils.getAuthHeader(), 'Accept': 'application/json'},
        credentials: "same-origin"
    }).then(resp => {
        return resp;
    }).catch(resp => {
        throw resp;
    });
}

function post(url: string, data: any) {
    return fetch(url, {
        method: 'POST',
        headers: {...JwtUtils.getAuthHeader(), 'Content-Type': 'application/json'},
        credentials: "same-origin",
        body: JSON.stringify(data)
    }).then(resp => {
        return resp;
    }).catch(resp => {
        console.error("Failed to sent data to " + url);
        throw resp;
    });
}

function postNonAuth(url: string, data: any) {
    return fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: "same-origin",
        body: JSON.stringify(data)
    }).then(resp => {
        return resp;
    }).catch(resp => {
        console.error("Failed to sent data to " + url);
        throw resp;
    });
}

function exchange(method: any, url: string, data: any) {
    let request : any = {
        method: method,
        headers: {...JwtUtils.getAuthHeader(), 'Content-Type': 'application/json'},
        credentials: "same-origin",
    }

    if (data) {
        request.body = JSON.stringify(data);
    }

    return fetch(url, request).then(resp => {
        return resp;
    }).catch(resp => {
        console.error("Failed to sent data to " + url);
        throw resp;
    });
}

function put(url: string, data: any) {
    let request : any = {
        method: 'PUT',
        headers: {...JwtUtils.getAuthHeader(), 'Content-Type': 'application/json'},
        credentials: "same-origin",
        body: JSON.stringify(data),
    };

    return fetch(url, request).then(resp => {
        return resp;
    }).catch(resp => {
        console.error("Failed to sent data to " + url);
        throw resp;
    });
}

function patch(url: string, data: any) {
    return fetch(url, {
        method: 'PATCH',
        headers: {...JwtUtils.getAuthHeader(), 'Content-Type': 'application/json'},
        credentials: "same-origin",
        body: JSON.stringify(data)
    }).then(resp => {
        return resp;
    }).catch((resp) => {
        console.error("Failed to sent data to " + url + " because of a network error");
        throw resp;
    });
}

function del(url: string, data: any) {
    return fetch(url, {
        method: 'DELETE',
        headers: {...JwtUtils.getAuthHeader(), 'Accept': 'application/json', 'Content-Type': 'application/json'},
        credentials: "same-origin",
        body: JSON.stringify(data)
    }).then(resp => {
        if (!resp.ok) {
            console.error("Response not OK (url: " + url + ")");

        }
        return resp;
    }).catch(reason => {
        console.error("Failed to delete data at " + url + " because of a network error");
    });
}