import React, { useContext, useState } from "react";
import Button from "../atoms/inputs/Button";
import Panel from "../atoms/layout/Panel";
import { MediumTitle, RegularText, SecondaryText } from "../atoms/Typography";
import Header from "../components/layout/Header";
import { LocalStorageS } from "../services/LocalStorageS";
import { AuthS } from "../services/AuthS";
import { AlertS, AlertType } from "../services/AlertS";
import PageContainer from "../atoms/layout/PageContainer";
import Alert from "@mui/material/Alert";
import BackdropLoader from "../components/backdropLoader/BackdropLoader";

const MailConfirmation: React.FC = () => {
  const recipient: string | null = LocalStorageS.get('recipient', true);
  const [loading, setLoading] = useState(false);
  const [mailSended, setMailSended] = useState(false);
  const [alert, setAlert] = useState<AlertType| null>();

  const resendVerificationMail = () => {
    setLoading(true);
    if (recipient) {
      AuthS.resendVerificationMail(recipient).then((success: boolean) => {
        if (success) {
          setAlert(AlertS.Alert.MAIL_SENDED);
          setMailSended(true);
        }
      }).catch(() => {
        setAlert(AlertS.Alert.SERVER_UNAVAILABLE);
      }).finally(() => {
        setLoading(false);
      })
    }
  }

  return (
    <>
      <BackdropLoader isOpen={loading}/>
      <Header />
      <PageContainer>
        <Panel>
          <MediumTitle>
            BESTÄTIGUNGSMAIL GESENDET
          </MediumTitle>
        </Panel>
        {alert && (
          <Alert severity={alert.severity}>
            {alert.message}
          </Alert>  
        )}
        <RegularText>
          Du bist fast am Ziel. Wir haben Dir eine E-Mail mit einem Aktivierungslink gesendet. Bitte klicke auf den Link, um Dein Konto zu aktivieren.
        </RegularText>
        { recipient && !mailSended && (
          <>
            <br/>
            <br/>
            <Button
              label={"Email erneut senden"}
              onClick={resendVerificationMail}
            />
            <Panel>
              <SecondaryText>
                E-Mail nicht erhalten? Kein Problem! Klicken Sie auf die Schaltfläche und wir senden Ihnen die E-Mail erneut zu
              </SecondaryText>
            </Panel>
          </>
        )}
      </PageContainer>
    </>
  );
}

export default MailConfirmation;