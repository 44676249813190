import React, { useContext, useEffect, useState } from 'react'
import Panel from '../../atoms/layout/Panel';
import { LightText, BiggerBoldText, RegularText } from "../../atoms/Typography";
import { DateS } from '../../services/DateS';
import QRCode from 'react-qr-code';
import { Ticket } from '../../services/PassS';
import { ContactContext } from '../../contexts/ContactContext';
import { Skeleton } from '@mui/material';

const DealTicket: React.FC<{
  ticket: Ticket
}> = ({
  ticket
}) => {
  const { refreshPass } = useContext(ContactContext);
  const [minutes, setMinutes] = useState<number | undefined>();
  const [seconds, setSeconds] = useState<number | undefined>();
  const { redeemedAt } = ticket; 

  useEffect(() => {
    setInterval(() => {
      const { minutes, seconds } = DateS.getTicketCountdown(ticket.validTill);
      setMinutes(minutes);
      setSeconds(seconds);
    }, 1000)
  }, [])

  useEffect(() => {
    if (seconds && seconds < 0) {
      refreshPass();
    }
  }, [seconds])

  return (
    <>
      <Panel>
        <BiggerBoldText>
          { ticket.dealTitle }
        </BiggerBoldText>
        <RegularText>
          { ticket.dealPartner }
        </RegularText>
      </Panel>
      { (minutes && seconds)? (
        <LightText>
          {`noch ${minutes} min und ${seconds}s gültig`}
        </LightText>
      ) : (
        <Skeleton variant='text' height={40} width={250} style={{ margin: 'auto' }}/>
      )}
      <br/>
      <Panel>
        {redeemedAt? (
          <LightText>
            {`Eingelöst bei: ${DateS.formatDate(redeemedAt, 'HH:MM:ss')}`} 
          </LightText>
        ) : (
          <QRCode
            value={`${process.env.REACT_APP_FE_PARTNER_SERVICE_URL}/scan-ticket?id=${ticket.id}`}
            size={128}
            style={{
              border: '2px solid black',
              padding: 15,
              borderRadius: 20
            }}
          />
        )}
      </Panel>
      <br/>
    </>
  );
}

export default DealTicket;

