import React, { useState, useEffect } from 'react'
import { Grid, Skeleton } from "@mui/material";
import { AccessTime } from '@mui/icons-material';
import { ItalicText, UnderlineText, AddressText } from "../../atoms/Typography";
import Panel from '../../atoms/layout/Panel';
import TimeFrame from "../timeFrame/TimeFrame";
import { AuthS, Contact } from '../../services/AuthS';

const DealHeader: React.FC<{
  partnerId: string,
  partnerAlias: string,
  title: string,
  timeFrames: any,
  byAppointment: boolean
}> = ({
  partnerId,
  partnerAlias,
  title,
  timeFrames,
  byAppointment
}) => {
  const [dealAddress, setDealAddress] = useState<Contact | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    AuthS.getProfileContactInformation(partnerId).then(address => {
      if (address) {
        setDealAddress(address);
      }
    }).finally(() => {
      setLoading(false);
    })
  }, [])

  return (
    <>
      <Panel textAlign='left'>
        <UnderlineText>
          { title }
        </UnderlineText>      
      </Panel>
      <Grid container spacing={1} alignContent='center' alignItems='center'>
        <Grid item>
          <AccessTime />
        </Grid>
        <Grid item>
          <ItalicText>
            {byAppointment && "nach Vereinbarung"}
            {!byAppointment &&
              timeFrames.map((timeFrame: any, index: number) => {
                return <TimeFrame key={index} timeFrame={timeFrame} />;
              })}
            </ItalicText>
        </Grid>
      </Grid>
      <Panel textAlign='left'>
        <AddressText>
          {partnerAlias.toUpperCase()}
        </AddressText>
          { ( loading || !dealAddress) ? (
            <>
              <Skeleton variant="text" width={175}/>
              <Skeleton variant="text" width={175}/>
            </>
          ) : (
            <>
              <AddressText>
              { `${dealAddress.addressStreet}  ${dealAddress.streetNumber}` }
              </AddressText>
              <AddressText>
              { `${dealAddress.zipCode}  ${dealAddress.city}` }
              </AddressText>
            </>
          )}
      </Panel>
    </>
  )
}

export default DealHeader;