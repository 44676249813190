import { useState, useEffect } from "react";
import { AuthS } from "../services/AuthS";
import Header from "../components/layout/Header";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom"
import { MediumTitle, RegularText, SecondaryText } from "../atoms/Typography";
import { TextField } from "../atoms/inputs/TextField";
import Button from "../atoms/inputs/Button";
import { AlertS } from "../services/AlertS";
import { Container } from "@mui/material";
import BackdropLoader from "../components/backdropLoader/BackdropLoader";

const ConfirmRegistrationPage: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [mailResended, setMailResended] = useState<boolean>(false);
 
  useEffect(() => {
    const token: string | null = searchParams.get('token');

    if (token) {
      setLoading(true);
      AuthS.confirmRegistration(token).then((confirmed: boolean) => {
        if (confirmed) {
          navigate('/login?event=' + AlertS.Event.REGISTRATION_COMPLETED);
        }
      }).finally(() => {
        setLoading(false);
      })
    }
  }, [])

  const resendMail = () => {
    setMailResended(true);
  }

  return (
    <>
    <BackdropLoader isOpen={loading}/>
    <Header />
    <Container>
      { mailResended ? (
        <>
          <MediumTitle>
            Mail gesendet
          </MediumTitle>
          <RegularText>
            Wir haben Dir eine E-Mail mit einem Aktivierungslink gesendet. Bitte klicke auf den Link, um Dein Konto zu aktivieren.
          </RegularText>
        </>
      ) : (
          <>
            <MediumTitle>
              Ungültiger Link
            </MediumTitle>
            <TextField 
              value={email} 
              onChange={setEmail}
              label={'E-mail'}
            />
            <SecondaryText>
              Der Link ist abgelaufen. Bitte geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen eine E-Mail mit einem neuen Link zu.
            </SecondaryText>
            <Button
              label={'Erneut senden'}
              onClick={resendMail}
              withMargin
            />
          </>
        )}
    </Container>
    </>
  );
}

export default ConfirmRegistrationPage;