import React, { useState } from 'react';
import styled from 'styled-components'
import { Skeleton } from '@mui/material';
import { RelativeBox } from './layout/Boxes';
import { ImageLabelText } from './Typography';

interface ImageProps {
  src: string,
  rounded?: boolean,
  width?: number,
  height?: number,
  hidden?: boolean,
  withBorder?: boolean,
  borderWidth?: number,
  borderColor?: string,
  objectFit?: string
}

export const ImageWithLabel: React.FC<{
  imgProps: ImageProps,
  label: string,
  position?: string,
  small?: boolean
}> = ({
  imgProps,
  label,
  position,
  small,
}) => {
  return (
    <RelativeBox style={{width: "100%", height: "100%"}}>
      <Image {...imgProps}/>
      <ImageLabelText small={small} position={position}>
        {label}
      </ImageLabelText>
    </RelativeBox>
  );
}

const StyledImage = styled.img<ImageProps>`
  height: ${props => `${props.height}px` || '100%'};
  width: ${props => props.width ? `${props.width}px` : '100%'};
  border-radius: ${props => props.rounded ? '50%' : 0};
  object-fit: ${props => props.objectFit || 'cover'};
  display: ${props => props.hidden ? 'none' : 'block'};
  border: ${props => props.withBorder ? ((props.borderWidth || 5) + 'px solid ' + ( props.borderColor || '#f5f5f5')) : 0} !important;
`;

export const Image: React.FC<ImageProps> = (props: ImageProps) => {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <>
      { loading && (
        <Skeleton 
          variant={props.rounded? 'circular' : 'rectangular'} 
          width={props.width} 
          height={props.height}
        />
      )}
      <StyledImage 
        {...props} 
        src={props.src}
        hidden={loading}
        onLoad={() => setLoading(false)}
      />
    </>
  );
}

