import React, { ReactNode } from "react";
import { SmallerText } from '../Typography';
import Checkbox from '@mui/material/Checkbox'
import { Alert, Box, Grid } from "@mui/material";

const StyledCheckBox: React.FC<{
  text?: ReactNode,
  checked: boolean,
  setChecked: (e: boolean) => any,
  error?: boolean,
  errorMessage?: string
  width?: string
}> = ({
  text,
  checked,
  setChecked,
  error,
  errorMessage,
  width
}) => {
  return (
    <>
    <Grid container alignItems={'center'} justifyContent='flex-start'>
      <Grid item xs={2}>
        <Checkbox
          checked={checked}
          onChange={(e: any) => setChecked(e.target.checked)}
          sx={{
            '&.Mui-checked': {
              color: '#edc662',
            },
          }}
        />
      </Grid>
      <Grid item xs={9} ml={1}>
        <SmallerText align="left">
          { text }
        </SmallerText>
      </Grid>
    </Grid>
      { error && (
        <Box mt={1}>
          <Alert severity="error" variant="filled">
            {errorMessage}
          </Alert>
        </Box>
      )}
    </>
  )
}

export default StyledCheckBox;