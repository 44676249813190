import React, { useContext, useState } from 'react'
import { Stack } from "@mui/material"
import { RelativeBox, AbsoluteBox } from "../../atoms/layout/Boxes";
import { ShareRounded, Language } from '@mui/icons-material';
import IconWrapper from '../../atoms/IconWrapper';
import StyledLine from "../../atoms/shapes/StyledLine";
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import { ContactContext } from '../../contexts/ContactContext';
import SharePopup from "../popups/SharePopup";
import { Deal } from '../../services/DealS';
import { RoutingS } from '../../services/RoutingS';

const DealShare: React.FC<{deal: Deal}> = ({ deal }) => {
  const { favoriteDeals, editFavoriteDeals } = useContext(ContactContext);
  const [sharing, setSharing] = useState<boolean>(false);
  const { website } = deal;

  return (
    <RelativeBox>
      <SharePopup 
        isOpen={sharing} 
        onClose={() => setSharing(false)} 
        deal={deal}
      />
      <StyledLine color={'white'} height={2} />
      <AbsoluteBox style={{ right: '7%', top: -25 }}>
        <Stack direction={'row'} spacing={2}>
          {website && (
            <IconWrapper big withoutShadowBox withoutOpacity bgColor={'white'} iconColor={'#212121'}>
              <Language sx={{ fontSize: 30}} onClick={() => RoutingS.openInNewTab(website)}/>
            </IconWrapper>
          )}
          <IconWrapper big withoutShadowBox withoutOpacity bgColor={'white'} iconColor={'#212121'}>
            { favoriteDeals.includes(deal.id) ? (
              <FavoriteRoundedIcon sx={{ fontSize: 30 }} onClick={ () => editFavoriteDeals(deal.id) }/>
            ) : (
              <FavoriteBorderRoundedIcon sx={{ fontSize: 30 }}  onClick={ () => editFavoriteDeals(deal.id) }/>
            )}
          </IconWrapper>
          <IconWrapper big withoutShadowBox withoutOpacity bgColor={'white'} iconColor={'#212121'}>
              <ShareRounded sx={{ fontSize: 30}} onClick={() => setSharing(true)}/>
          </IconWrapper>
        </Stack>
      </AbsoluteBox>
    </RelativeBox> 
  );
}

export default DealShare;