import styled  from 'styled-components';

interface Props {
  color?: string;
  height?: number;
  width?: string;
  maxWidth?: string
}

const Line = styled.hr<Props>`
  background-color: ${p => p.color};
  width: ${p => p.width};
  height: ${p => p.height + "px"};
  max-width: ${p => p.maxWidth};
`;

const StyledLine = (props: Props) => {
  return (
    <Line {...props} />
  );
}

export default StyledLine;