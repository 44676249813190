import React, { useContext, useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom"
import Header from "../components/layout/Header";
import { SecondaryText, PageTitle } from "../atoms/Typography"
import Panel from "../atoms/layout/Panel";
import { TextField } from "../atoms/inputs/TextField";
import Button from "../atoms/inputs/Button";
import Alert from '@mui/material/Alert';
import { AuthS } from "../services/AuthS";
import PageContainer from "../atoms/layout/PageContainer";
import BackdropLoader from "../components/backdropLoader/BackdropLoader";
import { AlertS, AlertType } from "../services/AlertS";
import { WindowContext } from "../contexts/WindowContext";

const LoginPage: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const [searchParams] = useSearchParams();
  const { isPhoneDevice } = useContext(WindowContext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertType | null>();

  useEffect(() => {
    const event: string | null = searchParams.get('event');
    if (event) {
      const alert: AlertType = AlertS.Alert[event];
      if (alert) {
        setAlert(alert);
      }
    }
  }, []);

  const handleLogin = () => {
    setLoading(true);
    AuthS.login({ 
      username: email,
      password
    }).then((alert: AlertType) => {
      if (alert === AlertS.Alert.SUCCESS) {
        navigate('/purchase-pass')
      } else {
        setAlert(alert);
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <>
      <BackdropLoader isOpen={loading}/>
      <Header />
      <PageContainer>
        <Panel>
          <PageTitle small={isPhoneDevice}>
            LOGIN
          </PageTitle>
        </Panel>
        {alert && (
          <Alert severity={alert.severity} variant='filled'>
            {alert.message}
          </Alert>  
        )}
        <Panel>
          <TextField 
            value={email} 
            onChange={setEmail}
            label={'E-mail'}
            type='email'
          />
        </Panel>
        <Panel>
          <TextField 
            value={password} 
            onChange={setPassword}
            label={'Passwort'}
            type='password'
          />
        </Panel>
        <SecondaryText style={{ textAlign: 'right'}}>
          <a href="/password-forgotten">
            Passwort vergessen?
          </a>
        </SecondaryText>
        <br/>
        <Panel>
          <Button
            label={'ANMELDEN'}
            onClick={handleLogin}
            maxWidth={'350px'}
          />
        </Panel>
        <SecondaryText>
          <a href="/register">
            Jetzt registrieren
          </a>
        </SecondaryText>
      </PageContainer>
    </>
  );
}

export default LoginPage;