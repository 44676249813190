import styled from "styled-components";

interface TextProps {
  large?: boolean,
  color?: string,
  align?: string,
  weight?: string,
  size?: string,
  small?: boolean,
  position?: string,
}


export const Title = styled.div<TextProps>`
  font-weight: bold;
  font-size: ${props => props.large ? '2rem' : '1.1rem' };
  color: ${props => props.color ? props.color : 'black' };
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: ${p => p.align};
  text-shadow: 0px 0px 6px rgba(255,255,255,0.7);
`;

export const PassTitle = styled.div<TextProps>`
  font-weight: bold;
  font-size: 1.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 2px;
  text-shadow: 0px 0px 6px rgba(255,255,255,0.7);
`;

export const PageTitle = styled.div<TextProps>`
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-size: ${props => props.small ? '2rem' : '3.35rem' };
`;


export const PostPreview = styled.div`
  quotes: "„" "“" "‚" "‘";
  font-style: italic;
  font-weight: 300;
  font-size: .9rem;
`;

export const LargeTitle = styled.div`
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
`;

export const Link = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  color: black;
`

export const ImageLabelText = styled.div<TextProps>`
  position: absolute;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  bottom: ${props => props.position? props.position : '40%'};
  font-size: ${props => props.small ? '0.9rem' : '1.5rem' };
`;

export const RegularBoldUpperText = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${props => props.color ? props.color : '#212121' };
  text-shadow: 0px 0px 6px rgba(255,255,255,0.7);
  letter-spacing: 1px;
`;

export const MediumTitle = styled.div<TextProps>`
  font-weight: bold;
  font-size: ${props => props.large ? '2rem' : '1.65rem' };
  text-transform: uppercase;
  text-shadow: 0px 0px 6px rgba(255,255,255,0.7);
  text-align: ${p => p.align};
`;

export const UnderlineText = styled.span`
  font-weight: bold;
  font-size: 1.15rem;
  border-bottom: 3px solid #edc662;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const UnderlineTitle = styled.span<any>`
  font-weight: bold;
  font-size: ${props => props.large ? '2.5rem' : '1.3rem' };
  border-bottom: 3px solid #edc662;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: 0px 0px 6px rgba(255,255,255,0.7);
`;

export const DistanceText = styled.span`
  font-weight: 450;
  font-style: italic;
  margin-left: 5px;
  text-shadow: 0px 0px 6px rgba(255,255,255,0.7);
  border-bottom: 2px solid #edc662;
`;

export const CustomText = styled.div<TextProps>`
  font-weight: ${props => props.weight};
  font-size: ${props => props.size};
  color: ${props => props.color};
`;

export const MenuItem = styled.span`
  font-size: 1rem;
  margin-left: 1rem;
  cursor: pointer;
  &:hover {
    border-bottom: 3px solid #edc662;
  };
`;

export const BurgerMenuItem = styled.div<TextProps | any>`
  font-size: ${props => props.small ? '1.4rem' : '1.75rem' };
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 5px 20px;
  a {
    &:hover {
      border-bottom: 3px solid #edc662;
    };
    padding: 0;
    margin: 0;
  }
  cursor: pointer;
`;

export const MediumText = styled.div<TextProps>`
  font-size: ${props => props.small ? '1.2rem' : '1.75rem' };
  text-shadow: 0px 0px 6px rgba(255,255,255,0.7);
  letter-spacing: .05rem;
  font-weight: 300;
  color: #212121;
`;

export const LightText = styled.div`
  font-weight: 300;
  font-size: 1rem;
  text-shadow: 0px 0px 6px rgba(255,255,255,0.7);
`;

export const InputErrorMessage = styled.div`
  color: red;
  font-weight: 200;
  font-size: .85rem;
  text-align: left;
  padding-left: 10px;
  margin-top: 3px;
`;

export const BiggerBoldText = styled.div<TextProps>`
  font-size: ${props => props.large? '1.30rem' : '1.15rem'};
  text-shadow: 0px 0px 6px rgba(255,255,255,0.7);
  font-weight: bold;
`;

export const InputLabel = styled.div`
  text-align: left;
  font-weight: 300;
`;

export const BoldText = styled.div<any>`
  font-weight: bold;
  font-size: 1rem;
  text-transform: ${props => props.upperCase? 'uppercase' : 'none'};
`;

export const BolderText = styled.div`
  font-weight: 550;
`;

export const SecondaryText = styled.div`
  color: #bdbdbd;
  font-family: 'Jost', sans-serif;
  font-weight: 350;
  font-size: .85rem;
  text-shadow: 0px 0px 6px rgba(255,255,255,0.7);
  letter-spacing: .05rem;
`;

export const LargeText = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
`;

export const RegularText = styled.div<any>`
  textOverflow: ellipsis;
  color: ${props => props.color ? props.color : '#212121' };
  font-size: ${props => props.large ? '1.3rem' : '1rem' };
  font-weight: 400;
  text-align: ${p => p.align};
`;

export const AddressText = styled.div`
  font-weight: 400;
  font-size: 1rem;
  textOverflow: ellipsis;
`;

export const ItalicText = styled.div`
  font-weight: 350;
  font-style: italic;
  color: ${props => props.color ? props.color : '#212121' };
`;
export const SmallText = styled.div`
  font-weight: 200;
  font-size: .7rem;
`;

export const SmallerText = styled.div<TextProps>`
  font-weight: 200;
  font-family: 'Jost', sans-serif;
  color: ${props => props.color ? props.color : '#212121' };
  font-size: .85rem;
  text-align: ${props => props.align};
`;
