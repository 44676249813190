import React, { useContext } from "react";
import styled from "styled-components";
import { RegularBoldUpperText, SecondaryText, LightText, SmallerText, BiggerBoldText } from "../atoms/Typography";
import { GradientContainer, AbsoluteBoxCentered, RelativeBox, RoundedTopBox, ProfilePhotoBackgroundBox } from "../atoms/layout/Boxes";
import StyledLine from '../atoms/shapes/StyledLine';
import PassAvatar from '../components/pass/PassAvatar';
import Panel from '../atoms/layout/Panel';
import { PassS, Ticket } from '../services/PassS';
import { DateS } from '../services/DateS';
import { ContactContext } from "../contexts/ContactContext";
import DealTicket from "../components/deal/DealTicket";
import Button from "../atoms/inputs/Button";
import { NavigateFunction, useNavigate } from "react-router-dom"
import ProfilePhoto from "../components/profilePhoto/ProfilePhoto";
import { Dialog, Slide, DialogContent } from "@mui/material";
import { WindowContext } from "../contexts/WindowContext";
import DealPopupCloseIcon from "../components/deal/DealPopupCloseIcon";

const StyledRelativeBox = styled(RelativeBox)`
  margin-top: 4.5em;
  margin-bottom: 4.5em;
`;

const PassPopup: React.FC<{
  isOpen: boolean,
  onClose: () => void
}> = ({
  isOpen,
  onClose
}) => {
  const { contact, pass, contactPhoto } = useContext(ContactContext);
  const { isPhoneDevice } = useContext(WindowContext);
  const navigate: NavigateFunction = useNavigate();
  
  const ticket: Ticket | undefined = pass? pass.ticket : undefined;

  if (!contact || !pass) {
    return null;
  }

  return (
    <Dialog 
      fullScreen={isPhoneDevice}
      open={isOpen}
      TransitionComponent={Slide}
      onClose={onClose}
      style={{
        margin: 10,
      }}
      PaperProps={{
        style: { borderRadius: 25, overflowX: 'hidden', zIndex: 10 }
      }}
    >
    <DialogContent
      style={{
        width: isPhoneDevice ? '100%' : '500px',
        padding: 0,
        backgroundImage: 'linear-gradient(to bottom, #ebebf4, #ffffff, #f0f0f8, #f0f0f8, #ebebf4)',
        overflowX: 'hidden',  
      }}
    >
      <DealPopupCloseIcon onClose={onClose} color={'#f5f5f5'}/>
    <GradientContainer>
      <RelativeBox style={{ width: '100%', height: 250}}>
        <ProfilePhotoBackgroundBox />
        <ProfilePhoto />
      </RelativeBox>
      <RegularBoldUpperText>
        { `${contact.firstName}  ${contact.lastName}` }
      </RegularBoldUpperText>
      <LightText>
        { `Geburtsdatum: ${DateS.formatDate(contact.birthday)}` }
      </LightText>
      <StyledRelativeBox>
        <StyledLine 
          height={3}
          color={'white'}
          width={'100%'}
        />
        <AbsoluteBoxCentered style={{ bottom: -35 }}>
          <PassAvatar pass={PassS.getByLabel(pass.passLabel)} size={'small'} withBorder/>
        </AbsoluteBoxCentered>
      </StyledRelativeBox>
      <Panel>
        <SecondaryText>
          DEIN PASS
        </SecondaryText>
      </Panel>
      <Panel>
        <BiggerBoldText>
          { `${pass.description} PASS` }
        </BiggerBoldText>         
      </Panel>
      <Panel>
        <LightText>
          { pass.validFrom? `Gültig ab ${DateS.formatDate(pass.validFrom)}` :  DateS.getPassCountdown(pass.validTill) }
        </LightText>
      </Panel>
      <RoundedTopBox bgColor={'white'} style={{ minHeight: 200, marginTop: '2.5em', opacity: pass.validFrom? .5 : 1 }}>
        { ticket ? (
          <DealTicket ticket={ticket} />
        ) : (
          <>
            <Panel>
              <RegularBoldUpperText>
                Herzlichen Glückwunsch
              </RegularBoldUpperText>
              <RegularBoldUpperText>
                zu deinem DFD Pass!
              </RegularBoldUpperText>
            </Panel>
            { !contactPhoto && (
              <Panel>
                <SmallerText style={{ width: '80%', margin: 'auto' }}>
                  Füge optional ein anderes Foto hinzu, um den Einlöseprozess für die Partner zu erleichtern.
                </SmallerText>
              </Panel>  
            )}
              { !pass.ticket && (
                <Button
                  label={'Dealübersicht'}
                  onClick={() => {
                    onClose()
                    navigate('/deals')
                  }}
                  width={'60%'}
                  maxWidth={'250px'}
                  withMargin
                  disabled={pass.validFrom}
                />
              )}
          </>
        )}
      </RoundedTopBox>
    </GradientContainer>
    </DialogContent>
  </Dialog>
  );
}

export default PassPopup;