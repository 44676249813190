import styled from 'styled-components';
import IconWrapper from '../IconWrapper';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const StyledInput = styled.input`
  display: none;
`;

const FilePickerIcon = ({
  onSelectFile
}: any) => {
  return (
    <IconWrapper
      bgColor={'grey'}
      iconColor={'white'}
    >
      <label htmlFor='file-input' style={{ display: 'flex' }}>
        <AddRoundedIcon />
      </label>
      <StyledInput
        id='file-input'
        type='file'
        name='file'
        accept='image/png, image/jpeg'
        onChange={ (event: any) => onSelectFile(event.target.files[0]) }
      />
    </IconWrapper>
  );
}

export default FilePickerIcon;