import React, {useContext} from 'react';
import { ImageWithLabel } from '../../atoms/Image';
import { WindowContext } from "../../contexts/WindowContext";
import { DataS } from '../../services/DataS';

const Regions: React.FC = () => {
  const { isPhoneDevice, isTabletDevice } = useContext(WindowContext);
  const region = DataS.REGIONS[0];

  return (
    <ImageWithLabel 
      small={isPhoneDevice}
      position='10%'
      label={region.label}
      imgProps={{ 
        src: region.img,
        height: isPhoneDevice ? 
          350 : isTabletDevice ?
          600 : 1000
      }}
    />
  );
}

export default Regions;