import { HttpS } from "./HttpS";
import { PassPlan } from "./PassS";

const PROVIDERS = {
  PAYPAL: "paypal",
  STRIPE: "stripe",
};

type OrderDTO = {
  productId: string;
  orderId?: string;
  startDate?: Date;
  couponId?: string;
};

interface HostedPage {
  url: string;
}

const BASE_URL = process.env.REACT_APP_PAYMENT_SERVICE_URL;

const createOrder = (
  paymentProvider: string,
  orderDto: OrderDTO
): Promise<any> => {
  return HttpS.post(
    `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/${paymentProvider}/order`,
    orderDto
  )
    .then((resp: Response) => {
      if (resp.status === 201) {
        return resp.text();
      }
    })
    .catch((err: Error) => {
      console.log(err);
    });
};

const verifyOrder = (provider: string, orderId: string) => {
  return HttpS.get(
    `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/${provider}/verify-order?orderId=${orderId}`
  ).then((resp: Response) => resp.ok);
};

const createCheckout = async (
  plan: PassPlan,
  couponId?: string,
  startDate?: Date
): Promise<HostedPage | null> => {
  let targetUrl = `${BASE_URL}/chargebee/hosted-checkout?planId=${plan.chargebeeId}`;
  if (couponId) {
    targetUrl += `&couponId=${couponId}`;
  }
  if (startDate) {
    targetUrl += `&startDate=${startDate}`;
  }
  const res = await HttpS.get(targetUrl);
  if (res.status === 201) {
    return res.json();
  }
  return null;
};

const verifyCheckout = async (hostedPageId: string): Promise<boolean> => {
  const res = await HttpS.get(
    `${BASE_URL}/chargebee/verify-checkout?hostedPageId=${hostedPageId}`
  );
  return res.ok;
};

export const PaymentS = {
  PROVIDERS,
  createOrder,
  verifyOrder,
  createCheckout,
  verifyCheckout,
};
