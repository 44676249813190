import React, { useContext } from "react";
import { Container, Stack } from '@mui/material';
import Footer from "../components/layout/Footer";
import Panel from "../atoms/layout/Panel";
import TeamMember from "../components/dfd/TeamMember";
import { DataS } from "../services/DataS";
import { PageTitle, CustomText} from '../atoms/Typography';
import Header from "../components/layout/Header";
import { WindowContext } from "../contexts/WindowContext";

const AboutUs: React.FC = () => {
	const { isPhoneDevice } = useContext(WindowContext);

	return (
		<>
			<Header />
			<Container maxWidth={'md'}>
				<Panel>
					<PageTitle small={isPhoneDevice}> 
						Über Uns
					</PageTitle>
				</Panel>
				<Panel>
					<CustomText color={'#f97316'} size={'1.2rem'}>
						Wir sind ein junges Team, welches sich zum Ziel gesetzt hat, die Kultur- und Freizeitszenen in deutschen Städten neu zu beleben, Menschen zusammenzubringen und dafür die richtigen Anreize zu bieten.
					</CustomText>
				</Panel>
				<Panel>
					<CustomText size={'1.2rem'}>
						Dein Freunde Deal ermöglicht dies schnell, günstig und ganz bequem über unsere Online Plattform. Dabei entstehen Win-Win-Situationen: Für Abo-Kunden, welche Zugriff auf tolle Deals haben und das eine oder andere Mal häufiger ausgehen können, und für Partner, welche dadurch höhere Umsätze erzielen und diese besser steuern können.
					</CustomText>
				</Panel>
				<Stack spacing={6} mt={10} mb={5}>
					{ DataS.DFD_TEAM.map((member, index) => {
						return (
							<TeamMember 
								key={index} 
								{...member} 
								reverse={index % 2 == 1} 
							/>
						);
					})}
				</Stack>
			</Container>
			<Footer />
		</>
	);
}

export default AboutUs;