import React, { useState, useContext } from 'react';
import { StickyBox, AbsoluteBox, YellowBackgroundBox, UserDropdownBox } from '../../atoms/layout/Boxes';
import UserMenu from '../userMenu/UserMenu';
import UserActionIcon from '../userActionIcon/UserActionIcon';
import { AppUserContext } from '../../contexts/AppUserContext';
import { Menu } from '@mui/icons-material';
import { Stack } from '@mui/material';
import DrawerMenu from '../menu/DrawerMenu';
import UserActionsPopup from '../userActionsPopup/UserActionsPopup';
import { WindowContext } from '../../contexts/WindowContext';
import { ContactContext } from '../../contexts/ContactContext';
import { Image } from '../../atoms/Image';
import PassPopup from '../../pages/PassPopup';
import { NavigateFunction, useNavigate, useLocation } from 'react-router-dom';
import Button from '../../atoms/inputs/Button';
import { useMediaQuery } from 'react-responsive';

const Header: React.FC = () => {
  const { isAuthenticated } = useContext(AppUserContext);
  const { isPhoneDevice } = useContext(WindowContext);
  const { showPass, setShowPass } = useContext(ContactContext);
  const [showDrawerMenu, setShowDrawerMenu] = useState<boolean>(false);
  const [isUserMenuOpen, setUserMenuOpen] = useState<boolean>(false);
  const navigate: NavigateFunction = useNavigate();
  const isBiggerDevice: boolean = useMediaQuery({ minWidth: 720 });
  const { pathname } = useLocation();
  
  return (
    <StickyBox>
      <PassPopup
        isOpen={showPass}
        onClose={() => setShowPass(false)}
      />
      <YellowBackgroundBox isPhone={isPhoneDevice}/>
      <AbsoluteBox left='0'>
        <Stack
          direction={'row'} 
          justifyContent={'center'} 
          alignItems={'center'} 
          ml={2}
          style={{
            position: 'fixed'
          }}
        >
          <Menu onClick={() => setShowDrawerMenu(true)}  sx={{ fontSize:40 }}/>
          <span onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
            <Image 
              src='/img/dfd_logo.svg' 
              width={isPhoneDevice? 100: 120} 
            />
          </span>  
        </Stack>
      </AbsoluteBox>  

      <DrawerMenu isOpen={showDrawerMenu} onClose={() => setShowDrawerMenu(false)} />

      { isAuthenticated? (
        <>
          <UserActionIcon 
            onClick={setUserMenuOpen} 
            isUserMenuOpened={isUserMenuOpen}
          />
          { isPhoneDevice? (
            <UserActionsPopup 
              isOpen={isUserMenuOpen} 
              showPass={() => setShowPass(true)}
              onClose={() => setUserMenuOpen(false)} 
            />
          ) : (
            <UserDropdownBox show={isUserMenuOpen}>
              <UserMenu closeMenu={() => setUserMenuOpen(false)}/>
            </UserDropdownBox>
          )}
        </>
      ) : (
        <>
          { (isBiggerDevice && pathname === '/') && (
            <Stack alignItems={'flex-end'} pt={3} pr={6}>
              <Button
                label={'ANMELDEN'}
                onClick={() => navigate('/login')}
                width={'200px'}
                color='secondary'
              />
          </Stack>
          )}
        </>
      )}
    </StickyBox>
  );
}

export default Header;
