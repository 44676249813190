import React, { useContext } from 'react';
import { RoutingS } from '../../services/RoutingS';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { BurgerMenuItem } from '../../atoms/Typography';
import Button from '../../atoms/inputs/Button';
import { Drawer, Box } from '@mui/material';
import { AppUserContext } from '../../contexts/AppUserContext';
import { WindowContext } from '../../contexts/WindowContext';

const DrawerMenu: React.FC<{
  isOpen: boolean,
  onClose: () => void, 
}> = ({
  isOpen,
  onClose
}) => {
  const { isAuthenticated } = useContext(AppUserContext);
  const { isPhoneDevice } = useContext(WindowContext);
  const navigate: NavigateFunction = useNavigate();
  const { pathname } = useLocation();

  return (
    <Drawer
      anchor={'left'}
      open={isOpen}
      onClose={onClose}
    >
      <Box mt={4} ml={2} mr={4} width={isPhoneDevice? 200: 300}>
        { RoutingS.MENU_OPTIONS.map(opt => {
          return (
            <BurgerMenuItem 
              key={opt.id} 
              small={isPhoneDevice} 
              onClick={ opt.external? () =>  window.location.replace(opt.href) : () => navigate(opt.href)}
              selected={opt.href === pathname}
            >
              <span style={{ borderBottom: opt.href === pathname? '3px solid #edc662' : 0}}>
                {opt.label} 
              </span>
            </BurgerMenuItem>
          );
        })}
        { !isAuthenticated && (
          <Button 
            withMargin
            label={'Anmelden'}
            onClick={() => {
              navigate('/login');
              onClose();
            }}
          />
        )}  
        <a href='https://partner.deinfreundedeal.de' style={{ position: 'absolute', bottom: 30, textAlign: 'center', color: 'grey', fontSize: isPhoneDevice? '1.2rem' : '1.5rem', marginLeft: 20, left: 0 }}>
          Zum Partnerbereich &gt;&gt;
        </a>
      </Box>
    </Drawer>
  );
}

export default DrawerMenu;
