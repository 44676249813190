import { useEffect, useContext } from "react";
import { Container, Grid } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import DealPreview from "../deal/DealPreview";
import { DealS, Deal } from "../../services/DealS";
import { useSearchParams } from "react-router-dom";
import { WindowContext } from "../../contexts/WindowContext";
import { RegularText } from "../../atoms/Typography";
import NavigationArea from "./NavigationArea";
import { ContactContext } from "../../contexts/ContactContext";
import { DealsContext } from "../../contexts/DealsContext";

const DealsArea = () => {
  const { deals, selectDeal, allDealsSeen, next } = useContext(DealsContext);
  const [searchParams] = useSearchParams();
  const { pass } = useContext(ContactContext);
  const { isPhoneDevice } = useContext(WindowContext);

  const isDealSelected = (deal: Deal) =>
    pass && pass.ticket && pass.ticket.dealId === deal.id;

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      DealS.getDeal(id).then((deal) => {
        if (deal) {
          selectDeal(deal);
        }
      });
    }
  }, []);

  return (
    <InfiniteScroll
      dataLength={deals.length}
      hasMore={!allDealsSeen}
      next={next}
      loader={<></>}
      endMessage={
        <RegularText align="center">Du hast alle Deals gesehen</RegularText>
      }
    >
      <Container maxWidth={"lg"}>
        <Grid container direction={isPhoneDevice ? "column" : "row"} mt={3}>
          {deals &&
            deals.map((deal, index) => {
              return (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <DealPreview
                    deal={deal}
                    dealSelected={isDealSelected(deal)}
                    onClick={() => selectDeal(deal)}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Container>
      <NavigationArea />
    </InfiniteScroll>
  );
};

export default DealsArea;
