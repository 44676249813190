import { ReactNode } from "react"

export type Category = {
  label: string,
  questions: Question[]
}

export type Question = {
  question: string,
  answer: ReactNode
}

const FAQ_QUESTIONS: Category[] = [
  {
    label: 'Pass',
    questions: [
      {
        question: "Was ist ein Dein Freunde Deal Pass?",
        answer: "Der Dein Freunde Deal Pass berechtigt dich, Deals bei unseren teilnehmenden Partnern in deiner Stadt/ Region einzulösen."
      },
      {
        question: "Wo kann ich einen Dein Freunde Deal Pass kaufen?",
        answer: "Registriere dich kostenfrei auf unserer Website. Im Login-Bereich kannst du dir dann je nach Bedarf einen Dein Freunde Deal Pass mit deiner gewünschten Laufzeit kaufen."
      },
      {
        question: "Welche verschiedenen Dein Freunde Deal Pass-Optionen habe ich?",
        answer: "Du kannst zwischen drei verschiedenen Pässen wählen: S (10 Tage); M (3 Monate); L (365 Tage)."
      },
      {
        question: "Ab wann ist mein Dein Freunde Deal Pass nach der Buchung gültig?",
        answer: "Du kannst deinen Pass sofort nach der Bezahlung nutzen und direkt sparen"
      },
      {
        question: "Kann ich meinen Dein Freunde Deal Pass auch ausdrucken?",
        answer: "Das geht leider nicht. Dein Freunde Deal funktioniert nämlich ganz praktisch online und ohne Karte oder Gutscheinblock."
      },
      {
        question: "Wie lang ist ein Deal nach der Einlösung gültig?",
        answer: "Nach der Einlösung eines Deals hast du eine Stunde Zeit, um den Deal zu benutzen. Danach ist der Deal erst am nächsten Tag wieder nutzbar."
      },
      {
        question: "Wie sehe ich, wie lange mein Dein Freunde Deal Pass noch gültig ist?",
        answer: "Im Login-Bereich unter „Mein Pass“ kannst du einsehen, wie lange dein Pass noch gültig ist."
      },
      {
        question: "Was passiert, wenn mein Dein Freunde Deal Pass abgelaufen ist?",
        answer: "Die Pässe S, M und L laufen jeweils nach 10 Tagen, 3 Monaten bzw. 1 Jahr automatisch aus. Danach kannst du einen neuen Pass deiner Wahl abschließen."
      },      
      {
        question: "Ist der Dein Freunde Deal Pass übertragbar?",
        answer: "Der Dein Freunde Deal Pass ist personengebunden, weshalb dein Name auf dem Pass steht. Zur Überprüfung darf der Partner dich nach einem Ausweisdokument fragen. Bitte habe hierfür Verständnis."
      },
      {
        question: "Kann man einen Dein Freunde Deal Pass verschenken?",
        answer:  <>Ja, wir stellen Gutscheine aus, die beim Kauf eines Deal Pakets eingelöst werden können. Schicke hierzu eine Mail an <a href="mailto:info@deinfreundedeal.de"><u>info@deinfreundedeal.de</u></a>.</>
      }
      
    ]
  },
  {
    label: 'Deals',
    questions: [
      {
        question: "Wo finde ich Deals, die ich nutzen kann?",
        answer: "Alle aktuellen Deals findest du jederzeit online auf unserer Website."
      },
      {
        question: "Was brauche ich, um einen Deal einzulösen?",
        answer: "Du brauchst einen gültigen Dein Freunde Deal Pass, ein internetfähiges, mobiles Endgerät (Smartphone oder Tablet) und gegebenenfalls ein Ausweisdokument."
      },
      {
        question: "Wie kann ich einen Deal einlösen?",
        answer: <>Die Einlösung eines Deal ist ganz einfach über ein mobiles Endgerät möglich. Schaue dir hierzu unsere Infografik unter <a href="https://www.deinfreundedeal.de/"><u>www.deinfreundedeal.de</u></a> an.</>,
      },
      {
        question: "Wie oft kann ich Deals nutzen?",
        answer: "Jeder Deal ist einmal am Tag und fünfmal im Jahr nutzbar. Du kannst jeden Deal also an fünf verschiedenen Tagen im Jahr benutzen."
      },
      {
        question: "Wie funktioniert ein 2 zu 1-Deal?",
        answer: "Bei einem 2 zu 1-Deal musst du nur die teurere Leistung bezahlen. Du sparst somit den Preis für die günstigere Leistung."
      },
      {
        question: "Kann Dein Freunde Deal in einer Gruppe genutzt werden?",
        answer: "Du kannst einen 2 zu 1 Deal immer für genau zwei Leistungen zum Preis von einer Leistung nutzen. Du kannst also einen Dein Freunde Deal Pass für zwei Personen nutzen, zwei Pässe für vier Personen, drei Pässe für sechs Personen, usw."
      },
      {
        question: "Wie erfahre ich von neuen Deals und Änderungen?",
        answer: "Alle Deals sind tagesaktuell auf unserer Website zu finden. Du verpasst also keine neuen Deals."
      }
    ]
  },
  {
    label: 'Sonstiges',
    questions: [
      {
        question: "Was passiert mit meinen personenbezogenen Daten?",
        answer: <>Uns ist der Datenschutz äußerst wichtig, daher geben wir deine Daten natürlich nicht an Dritte weiter. Die Datenschutzerklärung findest du <a href="/data-privacy"><u>hier</u></a>.</>
      },
      {
        question: "Wie kann ich als Kunde das Team von Dein Freunde Deal erreichen?",
        answer: "Du kannst uns ganz einfach telefonisch oder über Email erreichen. Die Kontaktdaten findest du auf der Startseite, wenn du ganz nach unten scrollst."
      }
    ]
  }
];

const DFD_TEAM = [
  {
      name: "Andreas Petermann",
      role: "Partner- und Kundenberater",
      desc: "Andreas ist unsere Ansprechperson für unsere Partner. Er sorgt dafür, dass die Arbeit mit Dein Freunde Deal so entspannt und erfolgreich wie möglich läuft. Mit ihm findet ihr den perfekten Deal.",
      img: "img/ueber-uns/andreas.jpg"
  },
  {
      name: "Joschua Bogner",
      role: "Marketing und Promotion",
      desc: "Joschua ist der Mann, der Dein Freunde Deal bekannt macht. Er plant die Akquise, Marketing- und Promotion-Kampagnen in eurer Stadt.",
      img: "img/ueber-uns/joshua.jpg"
  },
  {
      name: "Anton Weißbach",
      role: "Partner- und Kundenberater",
      desc: "Anton bietet den optimalen Support für unsere Partner und Kunden. Er steht jederzeit gern für Fragen rundum die Plattform zur Verfügung.",
      img: "img/ueber-uns/anton.jpg"
  },
  {
      name: "Tristan Feldbusch",
      role: "Softwareentwicklung, IT-Administration",
      desc: "Tristan ist der Kopf hinter dem System von Dein Freunde Deal, welches dazu beiträgt, dass in eurer Stadt die besten Deals leicht, schnell und bequem zu finden sind.",
      img: "img/ueber-uns/tristan.jpg"
  },
  {
      name: "Katharina Kraus",
      role: "Grafik und Design, Online Marketing",
      desc: "Katharina sorgt dafür, dass Dein Freunde Deal immer gut durchgestylt und anwenderfreundlich im World Wide Web vertreten ist.",
      img: "img/ueber-uns/katha.jpg"
  },
  {
      name: "Georg Ronny Müller",
      role: "Geschäftsführung / B2B",
      desc: "Ronny entwickelt Dein Freunde Deal strategisch weiter und ist Ansprechpartner für Großkunden.",
      img: "img/ueber-uns/ronny.jpg"
  }
]


const SHARED_MESSAGES = [
  {
    category: 'Freundschaft',
    messages: [
      "Friendship isn’t a big thing, it’s a million little things. (Freundschaft ist keine große Sache, es ist eine Million kleiner Sachen.)",
      "Friends are the most important ingredients in the recipe of life Freunde sind die wichtigsten Zutaten im Rezept des Lebens.)",
      "The love of family and the admiration of friends is much more important than wealth and privilege. – Charles Kuralt",
      "All the treasures of the earth can’t replace a good friend. – Voltaire (Alle Schätze der Erde können keinen guten Freund ersetzen.)"
    ]
  },
  {
    category: 'Komplimente',
    messages: [
      "Ich kann dir uneingeschränkt vertrauen.",
      "Bei dir fühle ich mich einfach wohl.",
      "Ich bin stolz auf dich.",
      "Ich bewundere, wie sehr du für deine Ziele arbeitest.",
      "Du bist ein richtig toller Freund, weil du immer für mich da bist.",
      "Du kannst echt gut zuhören.",
      "Mit deiner Art zauberst du mir immer ein Lächeln ins Gesicht.",
      "Echt toll, wie du andere motivieren kannst.",
      "Deine Schlagfertigkeit ist wirklich außergewöhnlich. Das gefällt mir",
    ]
  },
  {
    category: 'Danke',
    messages: [
      "Manchmal braucht man tausend Worte. Manchmal besucht man tausend Orte. Manchmal redet man um den heißen Brei. Und manchmal braucht‘s der Worte nur drei: Vielen lieben Dank!",
      "Viel zu oft ist die Unterstützung, die wir erfahren, selbstverständlich. Im Alltagseinerlei vergessen wir den sorgsamen Umgang Miteinander. Es ist nur ein kleines Wort, aber es entfaltet eine große Wirkung: DANKE, für alles was du für mich tust!",
      "Es ist an der Zeit, dass die Dankbarkeit Flügel bekommt und sich auf den Weg zu Dir macht, um sich gebührend zu bedanken.",
      "Danke für die helfende Hand. Thanks für das liebe Wort. Mercy, dass Du immer für mich da bist und niemals fort. Verlass ist immer auf Dich. Darum verbeuge ich mich."
    ]
  },
  {
    category: 'Ermutigung',
    messages: [
      "Wenn dir das Leben Zitronen gibt, mach Limonade draus.",
      "Kein Kuchen ist auch keine Lösung.",
      "Zusammen ist man weniger allein.",
      "Lieber gemeinsam statt einsam.",
      "A coffee a day keeps the doctor away.",
      "Wein auf Bier, das rat ich dir. Bier auf Wein, das rat ich dir!"
    ]
  },
  {
    category: 'Ermutigung',
    messages: [
      "Wenn dir das Leben Zitronen gibt, mach Limonade draus.",
      "Kein Kuchen ist auch keine Lösung.",
      "Zusammen ist man weniger allein.",
      "Lieber gemeinsam statt einsam.",
      "A coffee a day keeps the doctor away.",
      "Wein auf Bier, das rat ich dir. Bier auf Wein, das rat ich dir!"
    ]
  },
  {
    category: 'Sport',
    messages: [
      "Motivation ist das, was dich starten lässt. Gewohnheit ist das, was dich weitermachen lässt.",
      "Einen Strandkörper nur für den Sommer? Warum nicht für ́s ganze Leben?",
      "Du bist niemals zu alt, um dir ein anderes Ziel zu setzen oder einen neuen Traum zu träumen.",
      "Disziplin ist nur die Wahl zwischen dem was du jetzt willst und dem was du am meisten willst.",
      "Tu heute etwas, für das dir dein zukünftiges Ich danken wird",
      "Dein Körper kann alles! Es ist nur dein Gehirn, das du überzeugen musst.",
      "Today it hurts, tomorrow it works.",
      "The only time Success comes before Work is in the dictionary.",
      "Eat big, lift big, get big.",
      "Go hard or go home.",
      "Cardio, ist das Spanisch?"
    ]
  },
  {
    category: 'Ziele/ Erfolg',
    messages: [
      "Misserfolg ist nur ein temporärer Richtungswechsel, um dich für deinen nächsten Erfolg auszurichten.",
      "Das Leben ist zu kurz, um es nicht zu versuchen.",
      "„Man muss das Unmögliche versuchen, um das mögliche zu erreichen.“ (Hermann Hesse)",
      "Um etwas zu erreichen, was du zuvor nie hattest musst du etwas tun, was du niemals zuvor getan hast.",
      "Der beste Weg seine Zukunft vorherzusagen ist, sie selbst zu erschaffen.",
      "„To achieve what others won’t, you have to do what others don’t.” (Lazar Angelov)",
      "„Glaube, dass du kannst und du bist schon halb dort.“ (Greg Plitt)",
      "Setz dir ein Ziel. Mach dir einen Plan. Mach dich an die Arbeit. Bleib dabei. Erreiche dein Ziel.",
      "Success isn't just about what you accomplish in your life, it ́s about what you inspire others to do.",
      "Rom wurde auch nicht an einem Tag erbaut. Arbeite hart an dir und du wirst Ergebnisse sehen.",
      "Es ist nicht der Berg, den wir bezwingen, sondern uns selbst. (Edmund Hillary)"
    ]
  },
  {
    category: 'Wellness',
    messages: [
      "Die Welt gehört dem, der sie genießt.",
      '"Wenn deine Seele keinen Sonntag hat, dann verdorrt sie." Albert Schweitzer',
      '"Die Kunst des Ausruhens ist ein Teil der Kunst des Arbeitens." (John Steinbeck)',
      '"Ein Augenblick der Geduld kann vor großem Unheil bewahren, ein Augenblick der Ungeduld ein ganzes Leben zerstören." (Chinesische Weisheit)',
      '"Die größten Ereignisse sind nicht unsere lautesten, sondern unsere stillsten Stunden." (Friedrich Wilhelm Nietzsche)',
      '"Wenn der Mensch zur Ruhe gekommen ist, dann wirkt er" (Francesco Petrarca)"',
      '"Gar nichts tun, das ist die allerschwierigste Beschäftigung und zugleichdiejenige, die am meisten Geist voraussetzt" (Oscar Wilde)',
      '"Was ohne Ruhepausen geschieht, ist nicht von Dauer." (Ovid)',
      "Zeit fühlen -nichts tun, nichts wollen. Das ist Luxus.",
      "Ein Augenblick der Seelenruhe ist besser, als was du erstreben magst."
    ]
  },
  {
    category: 'Gemeinsame Zeit',
    messages: [
      "Die schönste Zeit im Leben sind die kleinen Momente, in denen du spürst, du bist zur richtigen Zeit, am richtigen Ort.",
      "Nicht warten bis die beste Zeit kommt, sondern die jetzige zur besten machen.",
      "Jetzt sind die guten alten Zeiten, nach denen wir uns in 10 Jahren zurücksehnen.",
      "Genau Du. Mitkommen, Spaß haben."
    ]
  }
];

const HOW_IT_WORKS_SLIDES = [
  {
    subject: "ANMELDEN",
    desc: 'Erstelle in wenigen Sekunden deinen kostenlosen Account auf unserer Plattform.',
    img: "/img/howitworks/1.png"
  },
  {
    subject: "PASS AUSWÄHLEN",
    desc: "Je nach deinem Bedarf kannst du einen DFD-Pass erwerben. Nach der Bezahlung hast du sofort Zugriff auf alle Deals.",
    img: "/img/howitworks/2.png"
  },
  {
    subject: "Deal auswählen",
    desc: "In der Dealübersicht findest du alle Deals von unseren teilnehmenden Partnern übersichtlich dargestellt und kannst sie nach Kategorie, Zeit und Entfernung filtern.",
    img: "/img/howitworks/3.png"
  },
  {
    subject: "Verabreden",
    desc: 'Sende eine nette Botschaft an deine Freunde und verabrede dich bei einem unserer Partner.',
    img: "/img/howitworks/4.png"
  },
  {
    subject: "Einlösen",
    desc: 'Informiere den Partner bei der Bestellung, dass du DFD-Nutzer bist. Du zeigst den Mitarbeitenden dann deinen Pass (S,M,L) auf deinem digitalen Endgerät.',
    img: "/img/howitworks/5.png"
  },
  {
    subject: "Verifizieren",
    desc: 'Die Partner kontrollieren die Gültigkeit deines Passes mit Hilfe deines Personalausweises und dem individuellen Bestätigungscode. Nun kannst du mit deinen Freunden den 2 zu 1 Deal genießen.',
    img: "/img/howitworks/6.png"
  }
];

export type Region = {
  img: string
  label: string
}

const REGIONS: Array<Region> = [
  {
    img: '/img/regions/1.jpg',
    label: 'STUTTGART UND UMGEBUNG',
  }
];

const OPTIONS = [
  {
    title: 'DEINE VORTEILSPLATTFORM',
    description: '...mit den passenden Locations für dich und deine Freunde. Unsere zahlreichen Partner der Freizeit- und Kulturszene bieten tolle Gelegenheiten für deine Verabredungen.',
    buttonLabel: 'JETZT Deals ENTDECKEN',
    buttonHref: '/deals',
    img: 'img/categories/caffe.jpg'
  },
  {
    title: 'VERABREDEN UND SPAREN',
    description: 'Wenn dir ein Deal gefällt, kannst du eine von unseren kreativen "Botschaften" hinzufügen und diesen direkt mit einem Terminvorschlag teilen.',
    buttonLabel: undefined,
    img: "img/categories/culture.jpg"
  },
  {
    title: 'DEINE REGION STÄRKEN',
    description: 'Mit deinem Pass tust du gleichzeitig gutes und unterstützt die regionale Freizeit- und Gatroszene nachhaltig.',
    buttonLabel: undefined,
    img: "img/categories/culture.jpg"
  }
]

export const DataS = {
  FAQ_QUESTIONS,
  DFD_TEAM,
  SHARED_MESSAGES,
  HOW_IT_WORKS_SLIDES,
  REGIONS,
  OPTIONS
}