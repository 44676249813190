import { useState }  from "react";
import Header from "../components/layout/Header";
import { RegularText, Title } from "../atoms/Typography";
import Panel from "../atoms/layout/Panel";
import { TextField } from "../atoms/inputs/TextField";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../atoms/inputs/Button";
import { AuthS } from "../services/AuthS";
import { AlertS } from "../services/AlertS";
import { useEffect } from "react";
import PasswordChecker from "../components/passwordChecker/PasswordChecker";
import PageContainer from "../atoms/layout/PageContainer";
import BackdropLoader from "../components/backdropLoader/BackdropLoader";

const PasswordResetPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState('');
  const [event, setEvent] = useState<string | undefined>();
  const navigate: NavigateFunction = useNavigate();
  const [isPasswordValid, setValidPassword] = useState<boolean>(false);
  const token: string | null = searchParams.get('token');
  const [repeatedPassword, setRepeatedPassword] = useState<string>('');
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      AuthS.verifyToken(token).then((tokenVerified: boolean) => {
        if (!tokenVerified) {
          navigate(`/password-forgotten?event=${AlertS.Alert.LINK_EXPIRED}`);
        } 
      }).catch(() => {
        setEvent(AlertS.Alert.SERVER_UNAVAILABLE);
      }).finally(() => {
        setLoading(false);
      })
    }
  }, [])

  const resetPasswordHandler = () => {
    if (validateForm()) {
      if (token) {
        AuthS.resetPassword(newPassword, token).then((success: boolean) => {
          if (success) {
            navigate('/login?event=' + AlertS.Alert.PASSWORD_CHANGED);
          }
        })
      }
    } else {
      setShowErrors(true);
    }
  }

  const validateForm = (): boolean => {
    return isPasswordValid && newPassword === repeatedPassword;
  }

  return (
    <>
      <BackdropLoader isOpen={loading}/>
      <Header />
      <PageContainer>
        <Panel>
          <Title large={true}>
            PASSWORT ZURÜCKSETZEN
          </Title>          
        </Panel>
        <RegularText>
          Gib dein neues Passwort ein
        </RegularText>
        <Panel>
          <TextField 
            value={newPassword} 
            onChange={setNewPassword}
            label={'Passwort'}
            type='password'
            error={!isPasswordValid && showErrors}
            errorMessage={'Das Passwort entspricht nicht den Richtlinien bedingungen.'}
          />
        </Panel>
        <PasswordChecker 
          password={newPassword} 
          showErrors={showErrors}
          setValidPassword={setValidPassword}
        />
        <Panel>
          <TextField 
            value={repeatedPassword} 
            onChange={setRepeatedPassword}
            label={'Passwort Wiederholung'}
            type='password'
            error={newPassword !== repeatedPassword && showErrors}
            errorMessage={'Passwörter stimmen nicht überein.'}
          />
        </Panel>
        <br/>
        <Panel>
          <Button
            label={'Passwort zurücksetzen'}
            onClick={resetPasswordHandler}
          />
        </Panel>
      </PageContainer>
    </>
  )
}

export default PasswordResetPage;