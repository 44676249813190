
import React from "react";
import { Stack } from "@mui/material";
import { Deal } from "../../services/DealS";
import { 
  FacebookShareButton, 
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  TelegramIcon,
  EmailIcon
} from "react-share";

const ICON_SIZE = 48;

const ShareButtons: React.FC<{
  deal: Deal
  description: string 
}> = ({
  deal,
  description
}) => {
  const { id, partnerAlias, title } = deal;
  const dealUrl = 'https://www.deinfreundedeal.de/deals?id=' + id;
  const toHashTag = (s: string): string => s.replace(/ /g,'');

  return (
    <Stack direction='row' spacing={2} ml={4}>
      <WhatsappShareButton url={dealUrl} title={title}>
        <WhatsappIcon round size={ICON_SIZE}/>
      </WhatsappShareButton>
      <FacebookShareButton url={dealUrl} quote={description} hashtag={toHashTag(partnerAlias)}>
        <FacebookIcon round size={ICON_SIZE} />
      </FacebookShareButton>
      <TelegramShareButton url={dealUrl} title={title}>
        <TelegramIcon round size={ICON_SIZE} />
      </TelegramShareButton>
      <LinkedinShareButton url={dealUrl} title={title} summary={description}>
        <LinkedinIcon round size={ICON_SIZE}/>
      </LinkedinShareButton>
      <TwitterShareButton url={dealUrl} title={title} hashtags={['deinfreundedeal', toHashTag(partnerAlias)]}>
        <TwitterIcon round size={ICON_SIZE} />
      </TwitterShareButton>
      <EmailShareButton url={dealUrl} subject={title} body={description}>
        <EmailIcon round size={ICON_SIZE} />
      </EmailShareButton>
    </Stack>
  );
}

export default ShareButtons;
