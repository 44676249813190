import React from 'react';
import { useMediaQuery } from "react-responsive";
import { Backdrop, CircularProgress } from '@mui/material';

const BackdropLoader: React.FC<{isOpen: boolean}> = ({isOpen}) => {
  const isPhone = useMediaQuery({ maxWidth: 480 });

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
      onClick={() => {}}
      >
      <CircularProgress style={{ color: 'black'}} size={isPhone? 48 : 80}/>
    </Backdrop>
  );
}

export default BackdropLoader;

