import React, { useContext, useState } from "react";
import {
  Drawer,
  Grid,
  Dialog,
  DialogContent,
  Stack,
  Slide,
  Checkbox,
} from "@mui/material";
import Button from "../../atoms/inputs/Button";
import Panel from "../../atoms/layout/Panel";
import { TimePicker } from "../../atoms/inputs/TimePicker";
import {
  RegularText,
  MediumTitle,
  BiggerBoldText,
} from "../../atoms/Typography";
import { DealS } from "../../services/DealS";
import { DateS } from "../../services/DateS";
import { GradientContainer } from "../../atoms/layout/Boxes";
import { WindowContext } from "../../contexts/WindowContext";
import { DealsContext } from "../../contexts/DealsContext";

const TimeFramePopup = ({ isOpen, onCancel }: any) => {
  const { isPhoneDevice } = useContext(WindowContext);

  return (
    <>
      {isPhoneDevice ? (
        <Drawer anchor="bottom" open={isOpen} onClose={onCancel}>
          <TimeFrame onCancel={onCancel} />
        </Drawer>
      ) : (
        <Dialog
          open={isOpen}
          onClose={onCancel}
          TransitionComponent={Slide}
          style={{
            margin: 10,
          }}
          PaperProps={{
            style: { borderRadius: 25 },
          }}
        >
          <DialogContent
            style={{
              width: "475px",
              padding: 0,
            }}
          >
            <TimeFrame onCancel={onCancel} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const TimeFrame = ({ onCancel }: any) => {
  const { updateFilter, filter } = useContext(DealsContext);
  const [timeFrame, setTimeFrame] = useState<any>(
    filter.timeFrame || DateS.getDefaultTimeFilter()
  );

  const handleConfirm = () => {
    updateFilter({ timeFrame });
    onCancel();
  };

  const updateTime = (key: string, value: any) => {
    setTimeFrame({
      ...timeFrame,
      [key]: value,
    });
  };

  return (
    <GradientContainer style={{ marginTop: "3em" }}>
      <MediumTitle>ZEITFENSTER WÄHLEN</MediumTitle>
      <Panel>
        <BiggerBoldText>Tage</BiggerBoldText>
      </Panel>
      <Grid container>
        {DateS.DAYS.map((day, index) => {
          return (
            <Grid item xs={4} key={index}>
              <Checkbox
                checked={timeFrame[day.name] || false}
                onChange={(e: any) =>
                  setTimeFrame({
                    ...timeFrame,
                    [day.name]: e.target.checked ? true : undefined,
                  })
                }
                sx={{
                  "&.Mui-checked": {
                    color: "#edc662",
                  },
                }}
              />
              {day.label}
            </Grid>
          );
        })}
      </Grid>
      <Panel>
        <BiggerBoldText>Uhrzeit</BiggerBoldText>
      </Panel>
      <Stack spacing={5}>
        <Stack direction={"row"} spacing={3} justifyContent={"center"}>
          <TimePicker
            inlinePlaceholder={"von"}
            time={DateS.hourToDate(timeFrame.startTime)}
            patchField={DealS.FilterField.START_TIME}
            onChange={(val: any) =>
              updateTime(DealS.FilterField.START_TIME, val.startTime)
            }
          />
          <RegularText>bis</RegularText>
          <TimePicker
            inlinePlaceholder={"bis"}
            time={DateS.hourToDate(timeFrame.endTime)}
            patchField={DealS.FilterField.END_TIME}
            onChange={(val: any) =>
              updateTime(DealS.FilterField.END_TIME, val.endTime)
            }
          />
        </Stack>
        <Stack direction={"row"} spacing={3} justifyContent={"center"}>
          <Button
            label={"ABBRECHEN"}
            width={"40%"}
            color={"secondary"}
            onClick={onCancel}
            maxWidth={"250px"}
          />
          <Button
            label={"ANWENDEN"}
            width={"40%"}
            onClick={handleConfirm}
            maxWidth={"250px"}
          />
        </Stack>
      </Stack>
      <br />
      <br />
    </GradientContainer>
  );
};

export default TimeFramePopup;
