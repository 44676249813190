import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from "@mui/material/Alert";
import { AlertColor, Slide } from '@mui/material';

const Toast: React.FC<{
  isOpen: boolean, 
  onClose: () => void,
  severity: AlertColor,
  message: string
}> = ({
  isOpen,
  onClose,
  severity,
  message
}) => {
  return (
    <Snackbar 
      open={isOpen} 
      anchorOrigin={{ 
        vertical: 'top',
        horizontal: 'center'
      }} 
      autoHideDuration={5000} 
      onClose={onClose}
      TransitionComponent={Slide}
    >
      <Alert 
        onClose={onClose}  
        variant='filled'
        severity={severity} 
        sx={{ width: '100%' }}
      >
        { message }
      </Alert>
    </Snackbar>
  );
}

export default Toast;