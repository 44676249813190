import {GreyRoundedBackground, RelativeBox} from "../../atoms/layout/Boxes";
import Panel from "../../atoms/layout/Panel";
import VideoBox from "../videoBox/VideoBox";
import CarouselWrapper from "../carousel/CarouselWrapper";
import {DataS} from "../../services/DataS";
import Paragraph from "./Paragraph";
import React from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import Button from "../../atoms/inputs/Button";

const WhyPhone = () => {
    const navigate: NavigateFunction = useNavigate();

    const nav = (href: string | undefined) => {
        if (href) { navigate(href) }
    }

    return (
        <>
            <RelativeBox>
                <GreyRoundedBackground
                    height={'190px'}
                    top={'-40px'}
                />
                <Panel>
                    <VideoBox src='mp4/landing-video.mp4' height={200} rounded withBorder/>
                </Panel>
            </RelativeBox>

        <CarouselWrapper >
            { DataS.OPTIONS.map((opt, index) => {
                return (
                    <Paragraph
                        key={index}
                        title={opt.title}
                        text={opt.description}
                        buttonLabel={undefined}
                        onClick={() => {}}
                        height={225}
                        center
                    />
                );
            })}
        </CarouselWrapper>
        {DataS.OPTIONS[0].buttonLabel && <Panel textAlign={"center"}>
            <Button
                label={DataS.OPTIONS[0].buttonLabel}
                onClick={() => nav(DataS.OPTIONS[0].buttonHref)}
                maxWidth={'300px'}
            />
        </Panel>}
    </>
    )
}

export default WhyPhone;