import React, { useContext, useEffect, useState } from 'react'
import { DistanceText } from "../../atoms/Typography";
import MyLocationRoundedIcon from '@mui/icons-material/MyLocationRounded';
import { LocationContext } from '../../contexts/LocationContext';
import { Deal, PartnerLocation } from '../../services/DealS';
import Skeleton from '@mui/material/Skeleton';
import { Stack } from '@mui/material';
import { WindowContext } from '../../contexts/WindowContext';

const DistancePreview: React.FC<{
  deal: Deal, 
  center?: boolean,
}> = ({ 
  deal,
  center
}) => {
  const { isDesktopDevice } = useContext(WindowContext);
  const { locationAvailable, partnerLocations, lastUpdated } = useContext(LocationContext);
  const [distance, setDistance] = useState<string>();
  const { partnerAlias } = deal;

  useEffect(() => {
    updateDistance();
  }, []);

  useEffect(() => {
    updateDistance();
  }, [lastUpdated]);

  const updateDistance = () => {
    const partnerLocation: PartnerLocation = partnerLocations[partnerAlias];
    if (partnerLocation) {
      const { distance } = partnerLocation;
      if (distance) {
        setDistance(distance);
      }
    }
  }

  if (!locationAvailable) {
    return null;
  }

  return (
    <Stack direction={'row'} justifyContent={isDesktopDevice || center? 'center' : 'initial'}>
      { (distance) ? (
        <>
          <MyLocationRoundedIcon fontSize="small"/>
          <DistanceText>
            { distance } km
          </DistanceText>
        </>
      ) : (
        <Skeleton variant="text" height={30} width={'80%'}/>
      )}
    </Stack>
  );
};

export default DistancePreview;