import React, { useState, useEffect, useContext }  from "react";
import validator from 'validator';
import Header from "../components/layout/Header";
import { PageTitle, RegularText } from "../atoms/Typography";
import { WindowContext } from "../contexts/WindowContext";
import Panel from "../atoms/layout/Panel";
import { TextField } from "../atoms/inputs/TextField";
import { useSearchParams } from "react-router-dom";
import Button from "../atoms/inputs/Button";
import { AuthS } from "../services/AuthS";
import { AlertS, AlertType } from "../services/AlertS";
import { Alert, Container } from "@mui/material";
import BackdropLoader from "../components/backdropLoader/BackdropLoader";
import PageContainer from "../atoms/layout/PageContainer";

const PasswordForgottenPage: React.FC = () => {
  const { isPhoneDevice } = useContext(WindowContext);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [mailSent, setMailSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [alert, setAlert] = useState<AlertType | null>();

  useEffect(() => {
    const event: string | null = searchParams.get('event');
    if (event) {
      const alert: AlertType = AlertS.Alert[event];
      if (alert) {
        setAlert(alert);
      }
    }
  }, []);

  const sendMailHandler = () => {
    setLoading(true);
    AuthS.requestResetPassword(email).then((success: boolean) => {
      if (success) {
        setAlert(AlertS.Alert.MAIL_SENDED);
        setMailSent(true);
      } else {
        setAlert(AlertS.Alert.SERVER_UNAVAILABLE);
      }
    }).catch(() => {
      setAlert(AlertS.Alert.SERVER_UNAVAILABLE);
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <>
      <BackdropLoader isOpen={loading}/>
      <Header />
      <PageContainer>
        <Panel>
          <PageTitle small={isPhoneDevice}>
            PASSWORT VERGESSEN
          </PageTitle>          
        </Panel>
        {alert && (
          <Alert severity={alert.severity}>
            {alert.message}
          </Alert>  
        )}
        { mailSent ? (
          <RegularText>
            Wir haben Dir eine E-Mail mit einem Link gesendet. Klick auf den Link, um ein neues Passwort für Dein Konto festzulegen. Der Link ist 30 Minuten gültig.
          </RegularText>
        ) : (
          <>
            <Panel>
              <RegularText>
                Trage hier Deine E-Mail-Adresse ein.
              </RegularText>
            </Panel>
            <TextField 
              value={email} 
              onChange={setEmail}
              label={'E-mail'}
              type='email'
              error={email.length > 5 && !validator.isEmail(email)}
              errorMessage={'Email Adresse ist nicht gültig'}
            />
            <Panel>
              <Button
                label={'E-MAIL SENDEN'}
                onClick={sendMailHandler}
                maxWidth={'300px'}
                withMargin
              />
            </Panel>
          </>
        )}
      </PageContainer>
    </>
  )
}

export default PasswordForgottenPage;