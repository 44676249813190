import { verify } from "jsonwebtoken";
import { LocalStorageS } from "../services/LocalStorageS";

const PUB_KEY_BASE64 : string | undefined = process.env.REACT_APP_RSA_PUBLIC_KEY;

if (!PUB_KEY_BASE64) {
  console.error("Unable to load public key");
}

export type AuthClaims = {
  sub: string,
  exp: number
}

const decodeToken = (jwt?: string | null) => {
  const token: string | null = jwt || LocalStorageS.getJWT();
  if (PUB_KEY_BASE64 && token) {
    try {
      return verify(
        token,
        Buffer.from(PUB_KEY_BASE64, 'base64'),
        {
          algorithms: ['RS256'],
          ignoreExpiration: false
        }
      );
    } catch(err) {
      console.error(err)
      return null;
    }
  }
}

const getAuthHeader = () => {
  const jwt = LocalStorageS.getJWT();
  if (jwt) {
    return {
      'Authorization': `Bearer ${jwt}`
    };
  }
}

const getProfileId = () => {
  const claims: any = decodeToken();
  if (claims) {
    return claims.profileId;
  }
}

export const JwtUtils = {
  decodeToken,
  getAuthHeader,
  getProfileId
}