import React from "react";
import styled from "styled-components";

const Container = styled.div`
	border-radius: 50%;
	background-color: white;
	display: flex;
	width: 60px;
	height: 60px;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: 1.2rem;
`;

const Badge: React.FC<{price: number}> = ({ price }) => {
	return (
		<Container>
			{price} €
		</Container>
	);
}

export default Badge;