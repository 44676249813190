import React, { useEffect, useState, useContext, ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

export const WindowContext = React.createContext<any>({});

export const WindowContextProvider: React.FC<{children: ReactNode}> = ({
  children
}) => {
  return (
    <WindowContext.Provider value={{
      isPhoneDevice: useMediaQuery({ maxWidth: 480 }),
      isTabletDevice: useMediaQuery({ maxWidth: 820 }),
      isDesktopDevice: useMediaQuery({ minWidth: 1280 })
    }}>
      { children}
    </WindowContext.Provider>
  );
}