import React from "react";
import { PassPlan } from "../../services/PassS";
import styled from "styled-components";

type Size = 'small' | 'medium' | 'big';

const Container = styled.div<{size: Size, bgColor: string, withBorder?: boolean}>`
  position: relative;
  background-color: ${props => props.bgColor};
  margin: auto;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: ${props => props.withBorder? '5px solid white' : 0};
  ${props => {
    if (props.size === 'small') {
      return `
        height: 5rem; 
        width: 5rem;
      `;
    } else if (props.size === 'medium') {
      return `
        height: 7rem; 
        width: 7rem;
      `;
    } else {
      return `
        height: 12rem; 
        width: 12rem;
      `;
    }
  }}
`;

const PassLabel = styled.div<{size: Size}>`
  color: white;
  ${props => {
    if (props.size === 'small') {
      return `
        font-size: 3rem;
      `;
    } else if (props.size === 'medium') {
      return `
      font-size: 4.5rem;
      `;
    } else {
      return `
      font-size: 6rem;
      `;
    }
  }}
`;

const PassAvatar: React.FC<{
  pass?: PassPlan, 
  size: Size,
  withBorder?: boolean
}> = ({
  pass,
  size,
  withBorder
}) => {  
  if (!pass) {
    return null;
  }
  
  return (
    <Container 
      size={size}
      bgColor={pass.color}
      withBorder={withBorder}
    >
      <PassLabel size={size}>
        {pass.label} 
      </PassLabel>
    </Container>
  );
}

export default PassAvatar;