import styled from 'styled-components';

const Button = styled.button<any>`
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  opacity: ${props => props.isSelected ? 1 : .85};
  border: ${props => props.isSelected ? '2px solid #212121;' : '2px solid #f5f5f5'};
  box-shadow: ${props => props.isSelected ? 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px' : 'none'};
  text-shadow: 0px 0px 6px rgba(255,255,255,0.7);
  background-color: #ebebf4;
  color: ${props => props.isSelected ? 'black' : 'grey'};
  white-space: nowrap;
  cursor: ${props => props.isSelected ? 'none' : 'pointer'};
`;

interface ChipProps {
  label: string,
  onSelect: () => void,
  isSelected?: boolean,
  itemId?: any
}

export const ToggleChip = ({label, onSelect, isSelected}: ChipProps) => {
  return (
    <Button onClick={onSelect} isSelected={isSelected}>
      {label}
    </Button>
  )
}


