import React, { useContext, useEffect, useState } from "react";
import { MapBox } from "../../atoms/layout/Boxes";
import { LocationContext } from "../../contexts/LocationContext";
import { WindowContext } from "../../contexts/WindowContext";
import { GeoPoint } from "../../services/MapS";
import GoogleMapWrapper from "./GoogleMapWrapper";
import { DealsContext } from "../../contexts/DealsContext";

const DealsMapPreview = () => {
  const { location, locationAvailable } = useContext(LocationContext);
  const { deals } = useContext(DealsContext);
  const { isPhoneDevice } = useContext(WindowContext);
  const [mapCenter, setMapCenter] = useState<GeoPoint>();

  useEffect(() => {
    if (locationAvailable && location) {
      const { latitude, longitude } = location;
      setMapCenter({
        lat: latitude,
        lng: longitude,
      });
    }
  }, [locationAvailable, location]);

  return (
    <MapBox small={false}>
      <GoogleMapWrapper
        deals={deals}
        mapCenter={mapCenter}
        mapZoom={14}
        markerSize={isPhoneDevice ? 64 : 128}
      />
    </MapBox>
  );
};

export default DealsMapPreview;
