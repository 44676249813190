import {Input} from "reactstrap";
import DatePicker from "react-datepicker";
import React, {forwardRef, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";

export const TimePicker = ({time, onChange, patchField, inlinePlaceholder}: any) => {
    const ref = React.createRef()


    const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => {
        const [tmpValue, setTmpValue] = useState<any>(value || '');

    return (
            <Input
                data-testid={"customInput"}
                ref={ref}
                placeholder={inlinePlaceholder}
                //onBlur={(e) => {tmpValue !== value ? handleOnBlur(e): null}}
                onChange={(e) => {setTmpValue(e.target.value); }}
                value={tmpValue}
                onClick={onClick}
                style={{
                    textAlign: 'center',
                    boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px',
                    width: '60%',
                }}
            />
        );
    });

    return (
        <DatePicker
            showTimeSelect showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    timeFormat="HH:mm"
                    popperPlacement="top"
                    dateFormat="HH:mm"
                    customInput={<CustomInput ref={ref}/>}
                     selected={time} onChange={(val: any) => {
            if (val) {
                let time = val.getHours() + ":00";
                if (time.length < 5) {
                    time = "0" + time;
                }
                onChange({[patchField]: time})
            } else {
                onChange({[patchField]: undefined})
            }

        }}

                    disabledKeyboardNavigation
        />
    )
}