import styled from "styled-components";
import { useContext } from 'react';
import { Carousel } from "react-responsive-carousel";
import { WindowContext } from "../../contexts/WindowContext";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Dot = styled.div<any>`
  transition: opacity .25s ease-in;
  opacity: ${p => p.selected ? 1 : .3};
  filter: alpha(opacity=30);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  background: ${p => p.color || 'black'};
  border-radius: 50%;
  width: ${p => p.small ? '8px' : '12px'};
  height: ${p => p.small ? '8px' : '12px'};
  display: inline-block;
  margin: 0 3px;
`;

const CarouselWrapper = ({
  children, 
  width,
  height,
  autoPlay, 
  infiniteLoop, 
  interval, 
  hideIndicators, 
  onChange,
  dotColor,
  index,
  swipeable = false
}: any) => {
  const { isPhoneDevice } = useContext(WindowContext);

  return (
    <div style={{ width: width, height: height }}>
      <Carousel
        autoPlay={autoPlay}
        interval={interval}
        selectedItem={index}
        onChange={onChange}
        swipeable={swipeable}
        showStatus={false}
        showThumbs={false}
        showIndicators={!hideIndicators}
        showArrows={swipeable? false : true}
        emulateTouch={true}
        infiniteLoop={infiniteLoop}
        renderIndicator={(onClickHandler, isSelected, index: number, label) => {
          return <Dot selected={isSelected} color={dotColor} small={isPhoneDevice}/>
        }}
      >
        {children}
      </Carousel>
    </div>

  );
}


export default CarouselWrapper;