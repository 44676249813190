import { Routes, Route } from "react-router-dom";
import { LocationContextProvider } from "./contexts/LocationContext";
import { ContactContextProvider } from "./contexts/ContactContext";
import { AppUserContextProvider } from "./contexts/AppUserContext";
import { WindowContextProvider } from "./contexts/WindowContext";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import DealsPage from "./pages/DealsPage";
import ChoosePlanPage from "./pages/ChoosePassPage";
import ConfirmRegistrationPage from "./pages/ConfirmRegistrationPage";
import PasswordForgottenPage from "./pages/PasswordForgottenPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import FaqPage from "./pages/FaqPage";
import AboutUsPage from "./pages/AboutUsPage";
import ImpressumPage from "./pages/ImpressumPage";
import DataPrivacyPage from "./pages/DataPrivacyPage";
import LandingPage  from "./pages/LandingPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import PaymentDispatcher from "./pages/PaymentDispatcherPage";
import MailConfirmation from "./pages/MailConfirmation";
import ShoppingCartPage from "./pages/ShoppingCartPage";
import { DealsContextProvider } from "./contexts/DealsContext";

const AppRoutes: React.FC = () => {
  return (
    <WindowContextProvider>
      <LocationContextProvider>
        <AppUserContextProvider>
          <ContactContextProvider>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={<DealsContextProvider />}>
                <Route path="/deals" element={<DealsPage />} />
              </Route>
              <Route path="/purchase-pass" element={<ChoosePlanPage />} />
              <Route path="/faq" element={<FaqPage />} />
              <Route path="/confirm-registration" element={<ConfirmRegistrationPage />} />
              <Route path="/password-forgotten" element={<PasswordForgottenPage />} />
              <Route path="/confirm-mail" element={<MailConfirmation />} />
              <Route path="/shopping-cart" element={<ShoppingCartPage />} />
              <Route path="/password-reset" element={<PasswordResetPage />} />
              <Route path="/ueber-uns" element={<AboutUsPage />} />
              <Route path="/impressum" element={<ImpressumPage />} />
              <Route path="/verify-payment" element={<PaymentDispatcher/>} />
              <Route path="/data-privacy" element={<DataPrivacyPage />} />
              <Route path="/change-password" element={<ChangePasswordPage />} />
              <Route path="*" element={<LandingPage />} />
            </Routes>
          </ContactContextProvider>
        </AppUserContextProvider>
      </LocationContextProvider>
    </WindowContextProvider>
  );
}

export default AppRoutes;