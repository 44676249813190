import React, { useContext } from "react";
import CarouselWrapper from '../carousel/CarouselWrapper';
import { Box, Stack } from '@mui/material';
import { DataS } from "../../services/DataS";
import { MediumText, RegularText, MediumTitle, BiggerBoldText } from "../../atoms/Typography";
import { Container } from "@mui/material";
import Panel from "../../atoms/layout/Panel";
import { WindowContext } from "../../contexts/WindowContext";
import { Image } from "../../atoms/Image";
import { GreyRoundedBackground } from "../../atoms/layout/Boxes";

const HowItWorks: React.FC = () => {
  const { isPhoneDevice, isDesktopDevice } = useContext(WindowContext);

  return (
    <Box position='relative' mt={isPhoneDevice? 10 : 20} mb={isPhoneDevice? 15 : 35}>
      <GreyRoundedBackground 
        height={isPhoneDevice? '190px' : '300px'}
        top={isPhoneDevice? '200px' : '170px'}
      />
      <Container maxWidth={'md'}>
        <Panel>
          <MediumTitle large={!isPhoneDevice}>
            SO FUNKTIONIERT'S
          </MediumTitle>
          <MediumText small={isPhoneDevice}>
            schnell, bequem, online
          </MediumText>
        </Panel>
        <CarouselWrapper height={isPhoneDevice? 550 : 350}>
          { DataS.HOW_IT_WORKS_SLIDES.map( (slide: any, index: number) => {
            return (
              <Stack 
                key={index}
                direction={isPhoneDevice ? 'column' : 'row-reverse'} 
                justifyContent='center'
                spacing={7}
                alignItems='center'
                style={{
                  height: isPhoneDevice? 550 : 350,
                }} 
              >
                <Image
                    src={slide.img}
                    width={isPhoneDevice? 220 : 275}
                    height={isPhoneDevice? 220 : 275}
                    objectFit={'contain'}
                    rounded
                  />
                <div style={{ maxWidth: isPhoneDevice? 300 : 'none', paddingLeft: isPhoneDevice? 0 :  100 }}>
                  <Panel textAlign={isPhoneDevice? 'center' : 'left'}>
                    <BiggerBoldText large={isDesktopDevice}>
                      { slide.subject.toUpperCase() }
                    </BiggerBoldText>
                  </Panel>
                  <Panel textAlign={isPhoneDevice? 'center' : 'left'}>
                    <RegularText large={isDesktopDevice}>
                      { slide.desc }
                    </RegularText>
                  </Panel>
                </div>
              </Stack>
            );
          })}
        </CarouselWrapper>
      </Container>
      </Box>
  );
}

export default HowItWorks;
