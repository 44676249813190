import HowItWorks from '../components/landingPage/HowItWorks';
import IntroductionDesktop from '../components/landingPage/IntroductionDesktop';
import Regions from '../components/landingPage/Regions';
import PassPreview from '../components/pass/PassPreview';
import DealsPreview from '../components/landingPage/DealsPreview';
import Header from '../components/layout/Header';
import {useContext} from 'react';
import {WindowContext} from '../contexts/WindowContext';
import Footer from '../components/layout/Footer';
import WhyDesktop from "../components/landingPage/WhyDesktop";
import WhyPhone from "../components/landingPage/WhyPhone";
import IntroductionPhone from '../components/landingPage/IntroductionPhone';
import IntroductionTitleDesktop from "../components/landingPage/IntroductionTitleDesktop";
import IntroductionTitlePhone from "../components/landingPage/IntroductionTitlePhone";

const LandingPage: React.FC = () => {
  const { isPhoneDevice, isTabletDevice } = useContext(WindowContext);
  
  return (
    <>
      <Header />
      { (isPhoneDevice || isTabletDevice) ? (
          <>
              <IntroductionPhone />
              <IntroductionTitlePhone />
              <WhyPhone/>
          </>
      ) : (
          <>
            <IntroductionDesktop />
            <IntroductionTitleDesktop />
            <WhyDesktop />
         </>
      )}

      <Regions />
      <HowItWorks />
      <PassPreview showTitle />
      <DealsPreview />
      <Footer />
    </>
  );
}

export default LandingPage;