import React, { useState, useEffect, useContext } from "react";
import { Deal, DealS } from "../../services/DealS";
import { Box, Skeleton, Stack } from "@mui/material";
import { NavigateFunction, useNavigate } from "react-router-dom";
import DealPopup from "../deal/DealPopup";
import Panel from "../../atoms/layout/Panel";
import { MediumText, MediumTitle } from "../../atoms/Typography";
import { Container } from "@mui/material";
import { WindowContext } from "../../contexts/WindowContext";
import DealPreview from "../deal/DealPreview";
import Button from "../../atoms/inputs/Button";
import { GreyRoundedBackground } from "../../atoms/layout/Boxes";
import CarouselWrapper from "../carousel/CarouselWrapper";
import { LocationContext } from "../../contexts/LocationContext";

const DealsPreview: React.FC = () => {
  const { isPhoneDevice, isDesktopDevice } = useContext(WindowContext);
  const { addPartners } = useContext(LocationContext);
  const navigate: NavigateFunction = useNavigate();
  const [selectedDeal, selectDeal] = useState<Deal | undefined>();
  const [deals, setDeals] = useState<Deal[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    DealS.getDeals(0, {})
      .then((data) => {
        const { content } = data;
        addPartners(DealS.groupDealsByPartner(content));
        setDeals(content);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Box
      mt={isDesktopDevice ? 20 : 5}
      pt={isDesktopDevice ? 4 : 2}
      position="relative"
    >
      <GreyRoundedBackground
        height={isDesktopDevice ? "250px" : "230px"}
        top={"80px"}
      />
      <DealPopup
        isOpen={selectedDeal !== undefined}
        onClose={() => selectDeal(undefined)}
        deal={selectedDeal}
      />
      <Box>
        <Panel>
          <Box mb={10}>
            <MediumTitle large={!isPhoneDevice}>DEINE DEALS</MediumTitle>
            <MediumText small={isPhoneDevice}>
              Weil es zu zweit schöner ist
            </MediumText>
          </Box>
        </Panel>
        {!loading && deals ? (
          <>
            {isPhoneDevice ? (
              <CarouselWrapper
                height={"320px"}
                auto
                interval={3000}
                dotColor={"#212121"}
              >
                {deals.map((deal: Deal, index: number) => {
                  return (
                    <DealPreview
                      withBorder
                      key={index}
                      deal={deal}
                      dealSelected={deal === selectedDeal}
                      onClick={() => selectDeal(deal)}
                      direction={"column"}
                      imgSize={200}
                    />
                  );
                })}
              </CarouselWrapper>
            ) : (
              <Container maxWidth="lg">
                <Stack direction={"row"} justifyContent="center" mt={3}>
                  {deals.slice(0, 4).map((deal: Deal, index: number) => {
                    return (
                      <DealPreview
                        withBorder
                        key={index}
                        deal={deal}
                        dealSelected={deal === selectedDeal}
                        onClick={() => selectDeal(deal)}
                        direction={"column"}
                      />
                    );
                  })}
                </Stack>
              </Container>
            )}
          </>
        ) : (
          <Panel>
            <Skeleton
              variant={"circular"}
              height={200}
              width={200}
              style={{
                margin: "2rem auto",
              }}
            />
            <Skeleton
              variant={"text"}
              width={150}
              height={40}
              style={{
                margin: "1rem auto",
              }}
            />
          </Panel>
        )}
        <Stack
          direction={isPhoneDevice ? "column" : "row"}
          mb={5}
          mt={isPhoneDevice ? 10 : 3}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={3}
        >
          <Button
            label={"ZUR DEALÜBERSICHT"}
            width={"70%"}
            maxWidth={"300px"}
            onClick={() => navigate("/deals")}
          />
          <Button
            label="FAQ"
            width={"70%"}
            color="secondary"
            maxWidth={"300px"}
            onClick={() => navigate("/faq")}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default DealsPreview;
