import styled from "styled-components";

interface BoxProps {
  big: boolean,
  bgColor: string,
  iconColor: string,
  withoutShadowBox: boolean,
  withoutOpacity: boolean,
}

const Box = styled.span<BoxProps>`
  width: ${props => props.big ? '50px' : '40px'};
  height: ${props => props.big ? '50px' : '40px'};
  background-color: ${props => props.bgColor ? props.bgColor : '#f0f0f8'};
  display: flex;
  box-shadow: ${props => props.withoutShadowBox ? 'none' : 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px'};
  justify-content: center;
  align-items: center;
  margin: auto;
  opacity: ${props => props.withoutOpacity ? 1 : .85};
  border-radius: 50px;
  cursor: pointer;
  svg {
    color: ${props => props.iconColor || '#212121;'};
  };
`;

const IconWrapper = (props: any) => {
  return (
    <Box {...props}>
      { props.children }
    </Box>
  );
}

export default IconWrapper;