import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Stack } from "@mui/material";

const Predicate = styled.div<any>`
  color: ${props => props.fulfilled ? 'green' : props.showError? 'red' : 'grey'};
  padding-left: 1em;
  font-weight: 300;
  text-align: left;
  font-size: .8rem;
`;

const PasswordChecker: React.FC<{
  password: string, 
  showErrors: boolean,
  setValidPassword: (valid: boolean) => void
}> = ({
  password, 
  showErrors,
  setValidPassword
}) => {
  const [longPassword, setLongPassword] = useState<boolean>(false);
  const [lowerCasePresent, setLowerCasePresent] = useState<boolean>(false);
  const [upperCasePresent, setUpperCasePresent] = useState<boolean>(false);
  const [upperAndLowerCase, setUpperAndLowerCase] = useState<boolean>(false);
  const [digitPresent, setDigitPresent] = useState<boolean>(false);

  useEffect(() => {
    if (password) {
        setLongPassword(password.length >= 8);
        setLowerCasePresent(password.toUpperCase() != password);
        setUpperCasePresent(password.toLowerCase() != password);
        setDigitPresent(/\d/.test(password));
    }
  }, [password]);

  useEffect(() => {
    if (lowerCasePresent &&  upperCasePresent) {
      setUpperAndLowerCase(true);
    }
  }, [lowerCasePresent, upperCasePresent])

  useEffect(() => {
    setValidPassword(longPassword && upperAndLowerCase && digitPresent);
  }, [longPassword, upperAndLowerCase, digitPresent])
 
  return (
    <Stack>
      <Predicate fulfilled={longPassword} showError={showErrors}>
        - mind. 8 Zeichen
      </Predicate>
      <Predicate fulfilled={upperAndLowerCase} showError={showErrors}>
        - mind. einen Groß- und einen Kleinbuchstaben 
      </Predicate>
      <Predicate fulfilled={digitPresent} showError={showErrors}>
        - mind. Eine Zahl
      </Predicate>
    </Stack>
  );
}

export default PasswordChecker;



