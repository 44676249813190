import { BolderText, BoldText, SmallText } from "../Typography";
import styled from 'styled-components';

const Circle = styled.div<any>`
  display: flex;
  justify-content: center;
  border-radius: 50px;
  flex-direction: column;
  width: ${props => props.size + 'px'};
  height: ${props => props.size + 'px'};
  background-image: linear-gradient(to right, #ffa100, #ffed04);
  text-align: center;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
`;

const DiscountBadge: React.FC<{
  size: number,
  discount: number,
  hideSpareText?: boolean,
}> = ({
  size,
  discount,
  hideSpareText
}) => {
  return (
    <Circle size={size}>
      <SmallText>
        spare ca.
      </SmallText>
      { (size >= 80)? (
        <BoldText>
          {Number(discount).toFixed(0)} €
        </BoldText>
      ) : (
        <BolderText>
          {Number(discount).toFixed(0)} €
        </BolderText>
      )}
    </Circle>
  );
}

export default DiscountBadge;