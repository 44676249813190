import styled from "styled-components";

interface BoxProps {
  left?: string,
  right?: string,
  top?: string,
  bottom?: string,
  width?: string,
  height?: string
}

export const MapBox = styled.div<any>`
  height: ${props => props.small? '450px' : '70vh'};
  width: 100%;
  margin-top: ${props => props.small? 0 : '-20px'};
`;

export const RelativeBox = styled.div<any>`
  position: relative;
  text-align: center;
  height: ${props => props.height || 'initial'};
  width: ${props => props.width || 'initial'};
`;

export const AbsoluteBox = styled.div<BoxProps>`
  position: absolute;
  text-align: center;
  height: ${props => props.height || 'initial'};
  width: ${props => props.width || 'initial'};
  left: ${props => props.left || 'initial'};
  bottom: ${props => props.bottom || 'initial'};
  right: ${props => props.right || 'initial'};
  top: ${props => props.top || 'initial'};
`;

export const FixedBox = styled.div<BoxProps>`
  position: fixed;
  text-align: center;
  height: ${props => props.height || 'initial'};
  width: ${props => props.width || 'initial'};
  left: ${props => props.left || 'initial'};
  bottom: ${props => props.bottom || 'initial'};
  right: ${props => props.right || 'initial'};
  top: ${props => props.top || 'initial'};
`;

export const UserDropdownBox = styled.div<any>`
  position: fixed;
  right: 10px;
  width: auto;
  top: 6em;
  display: ${props => props.show ? 'block' : 'none'};
  background-color: white;
  border-radius: 25px;
  padding: 5px;
`;

export const MapMarkerBox = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  cursor: pointer;
  z-index: 200;
  flex-direction: column;
`

export const MapMarkerLabelBox = styled.div<any>`
  background-color: black;
  border-radius: 25px;
  padding: 5px 10px;
  font-size: 1rem;
  color: white;
  opacity: .8;
  border: 2px solid #212121;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StickyBox = styled.div<any>`
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  height: 100px;
  top: 0;
  z-index: 15;
`;

export const ScrollBox = styled.div<any>`
  overflow-y: scroll; 
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DealsPreviewBackgroundBox = styled.div<any>`
  clip-path: ellipse(100% 58% at 47% 42%);
  background-image: linear-gradient(to bottom, #ffffff, #ebebf4, #f0f0f8, #f0f0f8, #ebebf4);
  position: absolute;
  height: 200px;
  width: 100%;
  bottom: 0;
  z-index: -1;
`;

export const ProfilePhotoBackgroundBox = styled.div<any>`
  clip-path: ellipse(100% 58% at 47% 42%);
  background-image: linear-gradient(to right, #ffa100, #ffed04);
  position: absolute;
  height: 350px;
  width: 100%;
  bottom: 70px;
`;

export const PassBackgroundBox = styled.div<any>`
  clip-path: ellipse(78% 38% at 50% 85%);
  background-image: linear-gradient(to right, #ffa100, #ffed04);
  position: absolute;
  height: 750px;
  width: 100%;
  bottom: 0;
  z-index: -1;
`;


export const PostBox = styled.div`
  background-color: #f5f5f5;
  text-align: left;
  max-width: 500px;
  margin: auto;
  font-weight: 400;
  padding: 10px 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  min-height: 170px;
`;


export const VerticalPaddingBox = styled.div`
  margin-bottom: 3em;
  margin-top: 3em;
`;

export const DealsMapBox = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: -1;
  top: -20px;
`;

export const FloatContainer = styled.div<any>`
  float: ${props => props.position};
  text-align: ${props => props.position};
  max-width: 400px;
`;

export const ShapedGradientBox2 = styled.div`
  clip-path: ellipse(100% 58% at 47% 42%);
  background-image: linear-gradient(to bottom, #ffffff, #ffffff, #ffffff, #f0f0f8, #ebebf4);
`;


export const ShapedGradientBox = styled.div`
  clip-path: ellipse(100% 58% at 47% 42%);
  background-image: linear-gradient(to bottom, #ffffff, #ebebf4, #f0f0f8, #f0f0f8, #ebebf4);
`;

export const RoundedBottomBox = styled.div<any>`
  background-color: ${props => props.bgColor === 'secondary' ? '#F0F0F8' : '#edc662'};
  text-align: center;
  clip-path: ellipse(100% 58% at 47% 42%);
`;

export const YellowBackgroundBox = styled.div<any>`
  background-image: linear-gradient(to right, #ffa100, #ffed04);
  padding-bottom: 10px;
  max-width: 550px;
  left: -30px;
  position: fixed;
  top: ${props => props.isPhone ? '-115px' : '-100px'};
  height: 300px;
  clip-path: ellipse(67% 62% at 33% 4%);
  width: 600px;
`;

export const RoundedTopBox = styled.div<any>`
  background-color: ${props => props.bgColor};
  text-align: center;
  clip-path: ellipse(76% 73% at 50% 78%);
  padding-top: 2.5em;
`;

export const AbsoluteBoxCentered = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;


export const GreyRoundedBackground = styled.div<any>`
  background-image: linear-gradient(to bottom, #ffffff, #ebebf4, #f0f0f8, #f0f0f8, #ebebf4);
  position: absolute;
  clip-path: ellipse(100% 58% at 47% 42%);
  height: ${props => props.height}; 
  top: ${props => props.top};
  width: 100%;
  z-index: -1;
`;

export const Container = styled.div`
  text-align: center;
`;

export const GradientRoundedContainer = styled.div<any>`
  text-align: center;
  clip-path: ${props => props.rounded === 'bottom' ? 'ellipse(100% 58% at 47% 42%)' : 'ellipse(76% 73% at 50% 78%)'};
  padding-bottom: 5px;
  padding-top: ${props => props.rounded === 'bottom' ? 0 : '3em'};
  background-image: ${props => props.rounded === 'bottom' ? 'linear-gradient(to bottom, #ffffff, #ffffff, #f0f0f8, #ebebf4)' : 'linear-gradient(to bottom, #ebebf4, #f0f0f8, #f0f0f8, #ffffff)'};
`;

export const GradientContainer = styled.div`
  text-align: center;
  background-image: linear-gradient(to bottom, #ffffff, #ebebf4, #f0f0f8, #f0f0f8, #ebebf4);
  // background-image: linear-gradient(to bottom, #f4f4fa, #fffbfd, #f8f7fb, #f4f4fa, #f0f0f8, #f4f4fa);
`;

export const StepContainer = styled.div`
  text-align: center;
  border: 1px solid #ebebf4;
  max-width: 500px;
`;

export const FlexBox = styled.div<any>`
  display: flex;
  flex-direction: ${props => props.isPhone ? 'column' : 'row'};
  flex-wrap: wrap;
`;

export const FlexItem = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 45%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-bottom: 1em;
`;


const ImageContainer = styled.div<any>`
  height: ${props => props.size};
  width: ${props => props.size};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: ${props => 'url(' + props.src + ')'};
  border-radius: 50%;
  border: ${props => props.withBorder ? '7px solid #edc662' : 'none'};
`;

export const ImageBox = ({
  src,
  size,
  withBorder
}: any) => {
  return (
    <ImageContainer 
      size={size} 
      src={src} 
      withBorder={withBorder}
    />
  );
}