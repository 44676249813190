import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Person, Edit, Logout } from '@mui/icons-material';
import { List, ListItem, ListItemIcon } from '@mui/material';
import IconWrapper from '../../atoms/IconWrapper';
import { LocalStorageS } from '../../services/LocalStorageS';
import { MenuItem } from '../../atoms/Typography';
import { ContactContext } from '../../contexts/ContactContext';

const UserMenu: React.FC<{
  closeMenu: () => void
}> = ({
  closeMenu
}) => {
  const { setShowPass } = useContext(ContactContext);
  const navigate = useNavigate();

  const userActions = [
    {
      icon: <Edit />,
      title: 'Passwort ändern',
      handler: () => navigate('/change-password')
    },
    {
      icon: <Person />,
      title: 'Mein Pass',
      handler: () => { 
        setShowPass(true)
        closeMenu();
      }
    },
    {
      icon: <Logout />,
      title: 'Abmelden',
      handler: () =>  {
        LocalStorageS.deleteJWT();
        closeMenu();
      }
    }
  ];
  
  return (
    <List dense>
      {userActions.map((action, index) => {
        return (
          <ListItem key={index} onClick={action.handler}>
            <ListItemIcon>
              <IconWrapper iconColor={'black'} bgColor={'#ebebf4'}>
                { action.icon }
              </IconWrapper>
            </ListItemIcon>
            <MenuItem>
              { action.title }
            </MenuItem>
          </ListItem>
        );
      })}
    </List>
  );
}

export default UserMenu;