import React, { useContext, useEffect } from "react";
import { PageTitle } from "../atoms/Typography";
import Panel from "../atoms/layout/Panel";
import PassPreview from "../components/pass/PassPreview";
import { WindowContext } from "../contexts/WindowContext";
import Header from "../components/layout/Header";
import { AbsoluteBox, GradientContainer, RelativeBox } from "../atoms/layout/Boxes";
import { ContactContext } from "../contexts/ContactContext";
import { NavigateFunction, useNavigate } from "react-router-dom";

const ChoosePlanPage: React.FC = () => {
  const { isPhoneDevice } = useContext(WindowContext);
  const { pass } = useContext(ContactContext);
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    if (pass) {
      navigate('/deals');
    }
  }, [pass])

  return (
    <>
      <Header />
      <GradientContainer style={{ zIndex: 1, position: 'relative' }}>
        <RelativeBox style={{ height: 'calc(100vh - 114px)', boxShadow: '#f5f5f5 0px -50px 36px -28px inset'}}>
          <Panel>
            <PageTitle small={isPhoneDevice}>
              PASS KAUFEN
            </PageTitle>
          </Panel>
          <AbsoluteBox width="100%" bottom="0">
            <PassPreview showTitle={false} />
          </AbsoluteBox>
        </RelativeBox>
      </GradientContainer>
    </>
  );
}

export default ChoosePlanPage;
