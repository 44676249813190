import React from "react";

const VideoBox: React.FC<{
  src: string,
  height: number,
  poster?: string,
  rounded?: boolean,
  withBorder?: boolean
}> = ({
  src,
  height,
  poster,
  rounded,
  withBorder
}) => {
  return (
    <video
      height={height}
      width={height}
      loop
      playsInline
      autoPlay
      muted
      poster={poster}
      style={{
        objectFit: 'fill',
        borderRadius: rounded? '50%' : 0,
        border: withBorder? '10px solid white' : 'none'
      }}
    >
      <source src={src} type='video/mp4' />
    </video>
  );
}

export default VideoBox;