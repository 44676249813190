import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  margin: auto;
  padding-left: 4em;
  padding-right: 4em;
  max-width: 500px;
`;

const PageContainer = (props: any) => {
  return (
    <Container>
      { props.children }
    </Container>
  );
}

export default PageContainer;