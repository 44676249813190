const get = (key: string, removeAfter?: boolean) => {
  if (key) {
    const item = localStorage.getItem(key);
    if (removeAfter) {
      localStorage.removeItem(key);
    }
    return item;
  } 
  return null;
}

const put = (key: string, value: any) => {
  if (key && value) {
    localStorage.setItem(key, value);
  }
}

const getJWT = (): string | null => {
  return get('jwt', false);
}

const putJWT = (jwt: string | null) => {
  put('jwt', jwt);
}

const deleteJWT = () => {
  localStorage.removeItem('jwt');
}

const remove = (key: string) => {
  localStorage.removeItem(key);
}

export const LocalStorageS = {
  get,
  put,
  putJWT,
  getJWT,
  deleteJWT,
  remove
}