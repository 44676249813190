import styled  from 'styled-components';

export const TextField = styled.input<any>`
    background-color: #FFFFFF;
    color: #000000;
    border-bottom-left-radius: ${props => (props.direction === 'left') ? '20px' : 0 };
    border-top-left-radius: ${props => (props.direction === 'left') ? '20px' : 0 };
    border-bottom-right-radius: ${props => (props.direction === 'right') ? '20px' : 0 };
    border-top-right-radius: ${props => (props.direction === 'right') ? '20px' : 0 };
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    height: inherit;
    width: 100%;
    margin-left: ${props => (props.direction === 'right') ? '3px' : 0 };
`;

export const Icon = styled.div`
    position: absolute;
    left: 10px;
    top: 10px;
`;