import { useState, useContext, useEffect } from "react";
import { PageTitle } from "../../atoms/Typography";
import TimeFramePopup from "../popups/TimeFramePopup";
import { Icon } from "./Styled";
import Panel from "../../atoms/layout/Panel";
import { RelativeBox, ScrollBox } from "../../atoms/layout/Boxes";
import { RoundedTextField } from "../../atoms/inputs/TextField";
import { Search } from "@mui/icons-material";
import { AppUserContext } from "../../contexts/AppUserContext";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import { Stack, Grid, Skeleton } from "@mui/material";
import { ToggleChip } from "../../atoms/shapes/ToggleChip";
import { DealS } from "../../services/DealS";
import _ from "lodash";
import { WindowContext } from "../../contexts/WindowContext";
import { DealsContext } from "../../contexts/DealsContext";
import { useDebouncedCallback } from "use-debounce";

const DealFilter = () => {
  const { isPhoneDevice } = useContext(WindowContext);
  const { isAuthenticated } = useContext(AppUserContext);
  const { updateFilter, filter } = useContext(DealsContext);
  const [availableCategories, setCategories] = useState<string[]>([]);
  const [pickingTimeframe, setPickingTimeframe] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { categories } = filter;

  const updateSeachQuery = useDebouncedCallback((val: string) => {
    updateFilter({ partnerAlias: val });
  }, 300);

  useEffect(() => {
    DealS.getCategories()
      .then((categories) => {
        if (categories) {
          setCategories(categories);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Stack spacing={1} style={{ paddingBottom: 10 }}>
      <TimeFramePopup
        isOpen={pickingTimeframe}
        onCancel={() => setPickingTimeframe(false)}
      />
      <PageTitle small={isPhoneDevice}>DEINE DEALS</PageTitle>

      <Grid container justifyContent={"center"}>
        <Grid item xs={10} sm={8} md={6} lg={4} style={{ maxWidth: 600 }}>
          <RelativeBox>
            <Icon>
              <Search />
            </Icon>
            <RoundedTextField
              placeholder={"Partner finden"}
              onChange={(e: any) => updateSeachQuery(e.target.value)}
            />
          </RelativeBox>
        </Grid>
      </Grid>
      {loading ? (
        <Skeleton
          variant="text"
          height={40}
          width={350}
          style={{ marginLeft: 20 }}
        />
      ) : (
        <div className="center" style={{ marginLeft: 15, marginRight: 15 }}>
          <div className="scrollmenu">
            {availableCategories.map((category: string, index: number) => {
              return (
                <ToggleChip
                  key={category}
                  itemId={index}
                  label={category}
                  isSelected={categories && categories.includes(category)}
                  onSelect={() => updateFilter({ categories: [category] })}
                />
              );
            })}
          </div>
        </div>
      )}
      <Panel smallMargin>
        <Stack direction={"row"} justifyContent={"center"} spacing={4}>
          <AccessTimeRoundedIcon onClick={() => setPickingTimeframe(true)} />
          {isAuthenticated && (
            <>
              {filter.favorites ? (
                <FavoriteRoundedIcon
                  onClick={() => updateFilter({ favorites: false })}
                />
              ) : (
                <FavoriteBorderRoundedIcon
                  onClick={() => updateFilter({ favorites: true })}
                />
              )}
            </>
          )}
        </Stack>
      </Panel>
    </Stack>
  );
};

export default DealFilter;
