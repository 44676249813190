import React, { useContext } from 'react';
import { Image } from '../../atoms/Image';
import Button from '../../atoms/inputs/Button';
import Panel from '../../atoms/layout/Panel';
import { RegularText, Title } from '../../atoms/Typography';
import { WindowContext } from "../../contexts/WindowContext";

const Paragraph: React.FC<{
  title?: string,
  text: string,
  buttonLabel: string | undefined,
  onClick: () => void,
  imageSrc?: string,
  height?: number,
  align?: string,
  center?: boolean
}> = ({
  title,
  text,
  buttonLabel,
  onClick,
  imageSrc,
  height,
  align,
  center
}) => {
  const { isDesktopDevice } = useContext(WindowContext);
  
  return (
    <div style={{ 
      maxWidth: isDesktopDevice? 450: 300, 
      height: height || 'inherit', 
      margin: center? 'auto' : 'inherit' }}
    >
      <Panel textAlign={align}>
        <Title large={isDesktopDevice}>
          {title}
        </Title>
      </Panel>
      { imageSrc && (
        <Panel>
          <Image
            src={imageSrc}
            width={200}
            height={200}
            rounded
          />
      </Panel>
      )}
      <Panel textAlign={align}>
        <RegularText large={isDesktopDevice}>
          { text }
        </RegularText>
      </Panel>
      <br/>
      {buttonLabel && <Panel textAlign={align}>
        <Button
            label={buttonLabel}
            onClick={onClick}
            maxWidth={'300px'}
        />
      </Panel>}
    </div>
  );
}

export default Paragraph;