import { useContext, useEffect, useState } from "react";
import Header from "../components/layout/Header";
import { PaymentS } from "../services/PaymentS";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RegularText, PageTitle } from "../atoms/Typography";
import { ContactContext } from "../contexts/ContactContext";
import { CircularProgress } from "@mui/material";
import { VerticalPaddingBox } from "../atoms/layout/Boxes";
import Panel from "../atoms/layout/Panel";
import PageContainer from "../atoms/layout/PageContainer";
import { AppUserContext } from "../contexts/AppUserContext";
import { WindowContext } from "../contexts/WindowContext";
import { AlertS } from "../services/AlertS";

const PaymentDispatcher = () => {
  const MAX_RETRIES = 10;
  const { refreshPass, setShowPass, pass } = useContext(ContactContext);
  const { isAuthenticated } = useContext(AppUserContext);
  const { isPhoneDevice } = useContext(WindowContext);
  const navigate = useNavigate();
  const [tick, setTick] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [counter, setCounter] = useState<number>(0);
  const [verified, setVerified] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const hostedPageId = searchParams.get("id");

  useEffect(() => {
    if (hostedPageId) {
      setTick(setInterval(verifyOrder, 1000));
    }
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostedPageId]);

  useEffect(() => {
    if (pass) {
      cleanup();
      navigate("/deals");
      setShowPass(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pass]);

  useEffect(() => {
    if (verified) {
      cleanup();
      if (isAuthenticated) {
        refreshPass();
      } else {
        navigate(`/login?event=${AlertS.Event.PAYMENT_VERIFIED}`);
      }
    }
    if (counter > MAX_RETRIES) {
      clearInterval(tick);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, verified]);

  const cleanup = () => {
    if (tick) {
      clearInterval(tick);
    }
  };

  const verifyOrder = () => {
    if (hostedPageId) {
      PaymentS.verifyCheckout(hostedPageId)
        .then(setVerified)
        .finally(() => {
          setCounter((prevCounter) => prevCounter + 1);
        });
    }
  };

  return (
    <>
      <Header />
      <PageContainer>
        <Panel>
          <PageTitle small={isPhoneDevice}>Bestellung bestätigen</PageTitle>
        </Panel>
        {loading ? (
          <>
            <RegularText large={!isPhoneDevice}>
              Überprüfung der Zahlung
            </RegularText>
            <VerticalPaddingBox>
              <CircularProgress
                color="inherit"
                size={isPhoneDevice ? 48 : 64}
              />
            </VerticalPaddingBox>
          </>
        ) : (
          <RegularText>
            Leider können wir Ihre Zahlung nicht verifizieren. Bitte versuchen
            Sie später den Kontakt ...
          </RegularText>
        )}
      </PageContainer>
    </>
  );
};

export default PaymentDispatcher;
