import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import { BoldText, Link, RegularText } from '../../atoms/Typography';
import Panel from '../../atoms/layout/Panel';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { WindowContext } from '../../contexts/WindowContext';

const Footer: React.FC = () => {
  const { isPhoneDevice } = useContext(WindowContext);
  const navigate: NavigateFunction = useNavigate();
  
  return (
    <Stack spacing={5} pb={3} pt={3} sx={{ backgroundColor: '#f0f0f8' }}>
      <Panel>
        <BoldText>
          Dein Freunde Deal
        </BoldText>
        <RegularText>
          (+49) 0170 2626853
        </RegularText>
        <RegularText>
          <a href="mailto:info@deinfreundedeal.de">
            info@deinfreundedeal.de
          </a>
        </RegularText>
      </Panel>
      <Stack 
        direction={isPhoneDevice? 'column' : 'row'} 
        spacing={isPhoneDevice? 1 : 15}
        alignItems={'center'} 
        justifyContent={'center'}
        mb={2}
      >
        <Stack direction={'row'} spacing={3}>
          <Link onClick={() => navigate('/ueber-uns')}> Über uns. </Link>
          <Link onClick={() => navigate('/faq')} > FAQ. </Link>
          <Link onClick={() => navigate('/impressum')}> Impressum. </Link>
          <Link href='https://partner.deinfreundedeal.de/register'> PARTNER WERDEN. </Link>
        </Stack>
        <img alt="Zahlungsarten" src="img/de-pp_plus-logo-quer_ohne_PUI_540x60px.png" height="30" width="270"/>
      </Stack>
    </Stack>
  );
}

export default Footer;