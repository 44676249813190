import { ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div<any>`
  margin: ${p => p.smallMargin ? '.5em auto .5em auto' : '1em auto 1em auto'};
  text-align: ${p => p.textAlign ? p.textAlign : 'center'};
`;

const Panel: React.FC<{
  smallMargin?: boolean,
  textAlign?: string,
  children: ReactNode
}> = ({
  children,
  smallMargin,
  textAlign
}) => {
  return (
    <Container smallMargin={smallMargin} textAlign={textAlign}>
      {children}
    </Container>
  );
}

export default Panel;

