import styled from 'styled-components';
import { AbsoluteBox } from "../../atoms/layout/Boxes";

export const BackIconAbsoluteBox = styled(AbsoluteBox)`
  z-index: 9999;
  top: 15px;
  left: 25px;
`;

export const CloseIconAbsoluteBox = styled(AbsoluteBox)`
  z-index: 9999;
  top: 15px;
  right: 25px;
`;

export const DiscountBadgeAbsoluteBox = styled(AbsoluteBox)`
  z-index: 1200;
  right: 7%;
  bottom: -40px;
`;

export const PaddingBox = styled.div`
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 1em;
  padding-bottom: 1em;
`;

