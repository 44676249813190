const mapSelectOptions = (options: string[]) => {
  return options.map(opt => {
    return {
      label: opt,
      value: opt
    }
  })
}

const replaceNullValues = (obj: any) => {
  for (let propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      obj[propName] = '';
    }
  }
  return obj
}

export const FormS = {
  mapSelectOptions,
  replaceNullValues
}