import { AlertColor } from "@mui/material"

export interface AlertType {
  severity?: AlertColor,
  message: string
}

const Event = {
  REGISTRATION_COMPLETED: 'REGISTRATION_COMPLETED',
  SUCCESS: 'SUCCESS',
  INVALID_CREDS: 'INVALID_CREDS',
  SERVER_UNAVAILABLE: 'SERVER_UNAVAILABLE',
  USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
  MAIL_SENDED: 'MAIL_SENDED',
  LINK_EXPIRED: 'LINK_EXPIRED',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',
  CHOOSE_DEAL: 'CHOOSE_DEAL',
  INVALID_OLD_PASSWORD: 'INVALID_OLD_PASSWORD',
  PAYMENT_VERIFIED: 'PAYMENT_VERIFIED'
}

const Alert: any = {
  REGISTRATION_COMPLETED: <AlertType>{
    message: "Vielen Dank! Dein Account ist jetzt aktiv. Sie können sich jetzt einloggen.",
    severity: 'success'
  },
  SUCCESS: <AlertType>{
    event: '',
    message: '',
  },
  INVALID_CREDS: <AlertType>{
    message: "Die E-Mail-Adresse oder das Passwort wurde falsch eingegeben.",
    severity: 'error'
  },
  SERVER_UNAVAILABLE: <AlertType>{
    message: "Der Server ist nicht erreichbar. Bitte prüfe Deine Internetverbindung.",
    severity: 'error'
  },
  USER_ALREADY_EXISTS: <AlertType>{
    message: "Diese E-Mail-Adresse wird bereits verwendet.",
    severity: 'warning'
  },
  MAIL_SENDED: <AlertType>{
    message: "E-Mail gesendet",
    severity: 'success'
  },
  LINK_EXPIRED: <AlertType>{
    message: "Der Link ist abgelaufen. Wir senden Ihnen eine E-Mail mit einem neuen link.",
    severity: 'error'
  },
  PASSWORD_CHANGED: <AlertType>{
    message: "Passwort wurde erfolgreich geändert. Sie können sich jetzt anmelden.",
    severity: 'success'
  },
  CHOOSE_DEAL: <AlertType>{
    message: "Melden Sie sich an und wählen Sie noch heute Ihr Angebot.",
    severity: 'success'
  },
  INVALID_OLD_PASSWORD: <AlertType>{
    message: "Vorausgesetztes altes Passwort stimmt nicht überein. Bitte überprüfen Sie den angegebenen Wert.",
    severity: 'error'
  },
  PAYMENT_VERIFIED: <AlertType>{
    message: 'Die Zahlung wurde erfolgreich bestätigt. Melden Sie sich an und wählen Sie noch heute Ihr Angebot aus.',
    severity: 'success'
  }
}

export const AlertS = {
  Alert,
  Event,
}