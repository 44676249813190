import { HttpS } from "./HttpS";
import { GeoPoint } from "./MapS";
import _ from "lodash";

const FilterField = {
  CITY: "city",
  CATEGORIES: "categories",
  PARTNER: "partnerAlias",
  TIME_FRAME: "timeFrame",
  START_TIME: "startTime",
  END_TIME: "endTime",
  FAVORITES: "favorites",
};

const CATEGORIES = [
  {
    src: "img/categories/coffee.png",
    label: "Café",
  },
  {
    src: "img/categories/restaurants.png",
    label: "Restaurants",
  },
  {
    src: "img/categories/culture.png",
    label: "Kultur",
  },
  {
    src: "img/categories/nightlife.png",
    label: "Nightlife",
  },
];

export type Deal = {
  id: string;
  title: string;
  description: string;
  saveEuro: number;
  partnerId: string;
  partnerAlias: string;
  lat: number;
  lng: number;
  timeFrames: any;
  image?: string;
  distance?: string;
  byAppointment: boolean;
  website: string | null;
};

export interface PartnerDistance {
  [partnerAlias: string]: PartnerLocation;
}

export type PartnerLocation = {
  coord: GeoPoint;
  distance?: string;
};

export type PartnerDeals = {
  partnerAlias: string;
  deals: Deal[];
};

export type Filter = {
  favorites?: boolean;
  city?: string;
  timeFrame?: any;
  categories?: string[];
  partnerAlias?: string;
};

export interface PageableResponse<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export type DealsDTO = {
  content: Deal[];
  last: boolean;
  totalPages: number;
};

const getDeals = (
  page: number,
  filter: Filter
): Promise<PageableResponse<Deal>> => {
  return HttpS.post(
    `${process.env.REACT_APP_DEAL_SERVICE_URL}/deals?page=${page}`,
    filter
  ).then((resp: Response) => {
    if (resp.ok) {
      return resp.json();
    }
  });
};

const getDeal = (id: string): Promise<Deal | undefined> => {
  return HttpS.get(
    `${process.env.REACT_APP_DEAL_SERVICE_URL}/deals/${id}`
  ).then((resp: Response) => {
    if (resp.ok) {
      return resp.json();
    }
  });
};

const getCategories = (): Promise<string[] | undefined> => {
  return HttpS.get(
    `${process.env.REACT_APP_DEAL_SERVICE_URL}/deals/categories`
  ).then((resp: Response) => {
    if (resp.ok) {
      return resp.json().then((categories: string[]) => categories);
    }
  });
};

const getCities = (): Promise<string[] | undefined> => {
  return HttpS.get(
    `${process.env.REACT_APP_DEAL_SERVICE_URL}/partners/cities`
  ).then((resp: Response) => {
    if (resp.ok) {
      return resp.json().then((cities: string[]) => cities);
    }
  });
};

const editFavoriteDeals = (dealId: string, add: boolean): Promise<boolean> => {
  return HttpS.patch(
    `${process.env.REACT_APP_DEAL_SERVICE_URL}/deals/own/favorites`,
    { dealId, add }
  ).then((resp: Response) => resp.ok);
};

const getFavoriteDeals = (): Promise<string[]> => {
  return HttpS.get(
    `${process.env.REACT_APP_DEAL_SERVICE_URL}/deals/own/favorites`
  ).then((resp: Response) => {
    if (resp.ok) {
      return resp.json();
    }
  });
};

const groupDealsByPartner = (deals: Deal[]): PartnerDeals[] => {
  return _(deals)
    .groupBy("partnerAlias")
    .map((deals, partnerAlias) => {
      return {
        partnerAlias,
        deals,
      };
    })
    .value();
};

const verifyDealAccessibility = async (dealId: string): Promise<boolean> => {
  const res = await HttpS.get(
    `${process.env.REACT_APP_DEAL_SERVICE_URL}/ticket/verify?dealId=${dealId}`
  );
  return res.status === 200;
};

const sortDealsByDistance = (deals: Deal[]): Deal[] => {
  return _.sortBy(deals, (deal: Deal) => parseInt(deal.distance || "", 10));
};

export const DealS = {
  CATEGORIES,
  FilterField,
  getDeal,
  getDeals,
  getCategories,
  getCities,
  editFavoriteDeals,
  getFavoriteDeals,
  groupDealsByPartner,
  sortDealsByDistance,
  verifyDealAccessibility,
};
