import React, { ReactNode } from "react";
import SpeedDial from '@mui/material/SpeedDial';

const NavigationIcon: React.FC<{
  right?: number,
  left?: number,
  bottom: number,
  withOpacity?: boolean,
  label: string,
  icon: ReactNode
}> = ({
  right,
  left,
  bottom,
  withOpacity,
  label,
  icon
}) => {
  return (
    <SpeedDial
      FabProps={{
        sx: {
          opacity: withOpacity? .7 : 1,
          bgcolor: '#f0f0f8',
          '&:hover': {
            opacity: 1,
            bgcolor: '#f0f0f8',
          }
        }
      }}
      direction={'down'}
      ariaLabel={label}
      sx={{ position: 'fixed', right: right, bottom: bottom, left: left }}
      icon={icon}
    />
  );
}

export default NavigationIcon;