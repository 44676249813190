import { useState, useEffect, createContext } from 'react';
import { PartnerDeals, PartnerDistance, PartnerLocation } from '../services/DealS';
import { MapS } from '../services/MapS';
import _ from 'lodash';

export const LocationContext = createContext<any>({});

export const LocationContextProvider = ({ children } : any) => {
  const [location, setLocation] = useState<GeolocationCoordinates>();
  const [locationAvailable, setLocationAvailable] = useState<boolean>(true);
  const [partnerLocations, setPartnerLocations] = useState<PartnerDistance>({});
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date());

  const config = {
    maximumAge: 1000 * 60 // Refresh location every minute
  }

  useEffect(() => {
    const { geolocation } = navigator; 
    if (navigator) {
      geolocation.watchPosition(updateLocation, onError, config);
    } else {
      setLocationAvailable(false);
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  // Refresh customer distance to the individual partners on every location update.
  useEffect(() => {
    if (location) {
      const { latitude, longitude } = location;
      _.forOwn(partnerLocations, (partnerLocation: PartnerLocation, partnerAlias: string) => {
        const { lat, lng } = partnerLocation.coord;
        const distance: string = MapS.getDistanceFromLatLonInKm(
          latitude, longitude,
          lat, lng
        );
        partnerLocations[partnerAlias] = Object.assign({}, partnerLocation, { distance: distance });
        setPartnerLocations(partnerLocations)
      });
      setLastUpdated(new Date());
    }
  }, [location]);

  const onError = (err: GeolocationPositionError) => {
    setLocationAvailable(false);
    const { code } = err;
    if (code === 1) {
      console.error('Geolocation access have been denied!');
    } else if(code === 2) {
      console.error('Position is unavailable!');
    } 
  }

  const updateLocation = (loc: GeolocationPosition) => {
    const { coords } = loc;
    setLocation(coords);
  }

  const addPartners = (partnersDeals: PartnerDeals[]): void => {
    const newLocations: PartnerDistance = {};
    if (locationAvailable) {
      _.forEach(partnersDeals, partnerDeals => {
        const { partnerAlias } = partnerDeals;
        if (!partnerLocations[partnerAlias]) {
          const { lat, lng } = partnerDeals.deals[0];
          newLocations[partnerAlias] = { coord: { lat, lng } };
        }
      });
      setPartnerLocations({ ...partnerLocations, ...newLocations });
    }
  }

  return (
    <LocationContext.Provider value={{
      locationAvailable,
      partnerLocations,
      lastUpdated,
      addPartners
    }}>
      { children }
    </LocationContext.Provider>
  );
}


