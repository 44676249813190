import styled from "styled-components";
import { InputLabel } from "../Typography";
import { useMediaQuery } from "react-responsive";
import { Alert, Box } from "@mui/material";

const Input = styled.input<any>`
  background-color: #ebebf4;
  height: 50px;
  width: 95%;
  border: 0;
  padding-left: 20px;
  color: ${props => props.disabled ? 'grey' : 'black'};
  -webkit-appearance: none;
  -moz-appearance: none;
`;

interface TextFieldProps {
  value?: string,
  onChange: (s: any) => void | undefined,
  label?: string,
  type?: string,
  error?: boolean,
  errorMessage?: string,
  disabled?: boolean,
  isPhone?: boolean,
  placeholder?: string,
  direction?: string,
  defaultValue?: string,
}

export const TextField = ({
  value,
  onChange,
  label,
  type,
  error,
  errorMessage,
  disabled
}: TextFieldProps) => {
  const isPhone = useMediaQuery({ maxWidth: 480 });

  return (
    <>
      <InputLabel>
        {label}
      </InputLabel>
      <Input
        type={type ? type : ''}
        value={value}
        onChange={(e: any) => onChange(e.currentTarget.value)}
        disabled={disabled}
        isPhone={isPhone}
      />
      {error && (
        <Box mt={1}>
          <Alert severity="error" variant="filled">
            {errorMessage}
          </Alert>
        </Box>
      )}
    </>
  )
}

const RoundedInput= styled.input<any>`
  border-radius: 20px;
  height: 42px;
  width: 100%;
  margin-bottom: 5px;
  padding: 0;
  text-indent: 40px;
  border: 0;
  text-overflow: ellipsis;
`;

export const RoundedTextField = (props: any) => {
  return (
    <RoundedInput {...props}/>
  );
}

