import {RelativeBox} from "../../atoms/layout/Boxes";
import {MediumText, PageTitle} from "../../atoms/Typography";
import React, {useContext} from "react";
import {WindowContext} from "../../contexts/WindowContext";


const IntroductionTitleDesktop = () => {
    const { isDesktopDevice } = useContext(WindowContext);

    return (
        <div style={{marginBottom: 40}}>
            <RelativeBox width='100%'>
                <PageTitle small={!isDesktopDevice}>
                    Gemeinsam Deals entdecken
                </PageTitle>
                <MediumText small={!isDesktopDevice}>
                    und Freundschaften pflegen
                </MediumText>
            </RelativeBox>
        </div>
    )
}

export default IntroductionTitleDesktop;