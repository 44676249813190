import React from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import styled from 'styled-components';
import { AbsoluteBox } from "../../atoms/layout/Boxes";

const CloseIconAbsoluteBox = styled(AbsoluteBox)`
  z-index: 10;
  top: 15px;
  right: 25px;
`;

const DealPopupCloseIcon: React.FC<{
  onClose: () => void,
  color: string
}> = ({
  onClose,
  color
}) => {
  return (
    <>
      <CloseIconAbsoluteBox>
        <CloseRoundedIcon 
          fontSize={'large'}
          onClick={() => onClose()} 
          sx={{
            color: color, 
            border: '3px solid ' + color, 
            borderRadius: 25,
          }}/>
      </CloseIconAbsoluteBox>
    </>
  );
};

export default DealPopupCloseIcon;