import React, { useState, useContext } from "react";
import { MediumTitle, BoldText, SmallerText } from "../../atoms/Typography";
import { TextField } from "../../atoms/inputs/TextField";
import { DateS } from "../../services/DateS";
import { FlexBox, FlexItem } from "../../atoms/layout/Boxes";
import Button from "../../atoms/inputs/Button";
import { WindowContext } from "../../contexts/WindowContext";
import { Contact } from "../../services/AuthS";
import Panel from "../../atoms/layout/Panel";
import { LocalStorageS } from "../../services/LocalStorageS";

const ShoppingForm: React.FC<{
  contact: Contact,
  updateField: (key: string, val: any) => void
}> = ({
  contact,
  updateField
}) => {
  const { firstName, lastName, birthday, addressStreet, streetNumber, city, zipCode, startDate, filled } = contact;
  const [preview, setPreview] = useState<boolean>(filled);
  const { isPhoneDevice } = useContext(WindowContext);
  
  return (
    <Panel>
      <MediumTitle>
        DEIN PASS 
      </MediumTitle>
      { preview ? (
        <>
          <BoldText>
            { `${firstName}  ${lastName}` }
          </BoldText>
          <SmallerText>
            { DateS.formatDate(birthday) }
          </SmallerText>
          <SmallerText>
            { `${addressStreet}  ${streetNumber}` }
          </SmallerText>
          <SmallerText>
            { `${zipCode}  ${city}` }
          </SmallerText>
          <SmallerText>
            Startdatum: { DateS.todayDate('DD.MM.yyyy') }
          </SmallerText>
          <Button
            label={'Bearbeiten'}
            onClick={() => setPreview(false)}
            width={'60%'}
            maxWidth={'200px'}
            withMargin
          />
        </>
      ) : (
        <>
          <FlexBox isPhone={isPhoneDevice}>
            <FlexItem>
              <TextField 
                value={firstName} 
                onChange={(val) => updateField('firstName', val)}
                label={'Vorname'}
              />
            </FlexItem>
            <FlexItem>
              <TextField 
                value={lastName} 
                onChange={(val) => updateField('lastName', val)}
                label={'Nachname'}
              />
            </FlexItem>
            <FlexItem>
              <TextField 
                type={'date'}
                value={birthday} 
                onChange={(val) => updateField('birthday', val)}
                label={'Geburtsdatum'}
              />
            </FlexItem>
            <FlexItem>
              <TextField 
                type={'date'}
                value={DateS.formatDate(startDate, 'yyyy-MM-DD') || DateS.todayDate()} 
                onChange={(val) => {
                  LocalStorageS.put('startDate', val);
                  updateField('startDate', new Date(val).toISOString());
                }}
                label={'Startdatum'}
              />
            </FlexItem>
          </FlexBox>
          <br />
          <MediumTitle>
            RECHNUNGSADRESSE 
          </MediumTitle>
          <br />
          <FlexBox isPhone={isPhoneDevice}>
            <FlexItem>
              <TextField 
                value={addressStreet} 
                onChange={(val) => updateField('addressStreet', val)}
                label={'Straße'}
              />
            </FlexItem>
            <FlexItem>
              <TextField 
                value={streetNumber} 
                onChange={(val) => updateField('streetNumber', val)}
                label={'Hausnummer'}
              />
            </FlexItem>
            <FlexItem>
              <TextField 
                value={city} 
                onChange={(val) => updateField('city', val)}
                label={'Wohnort'}
              />
            </FlexItem>
            <FlexItem>
              <TextField 
                value={zipCode} 
                onChange={(val) => updateField('zipCode', val)}
                label={'Postleitzahl'}
              />
            </FlexItem>
          </FlexBox>
        </>
      )}
    </Panel>
  );
}

export default ShoppingForm;