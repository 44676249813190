import React, { useContext } from "react";
import Footer from "../components/layout/Footer";
import Header from '../components/layout/Header';
import { BoldText, MediumTitle, PageTitle, RegularText } from '../atoms/Typography';
import { Grid, Container, Stack } from "@mui/material";
import { Category, DataS, Question } from "../services/DataS";
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';
import Panel from "../atoms/layout/Panel";
import { WindowContext } from '../contexts/WindowContext';

const FaqPage: React.FC = () => {
  const { isPhoneDevice } = useContext(WindowContext);

  return (
    <>
      <Header/>
      <Panel>
        <PageTitle small={isPhoneDevice}>
          FAQ
        </PageTitle>
      </Panel>
      <Panel textAlign="center">
        <RegularText>
          Hier beantworten wir häufig gestellte Fragen.
        </RegularText>
      </Panel>
      <br/>
      <Container fixed>
        <BoldText upperCase>
          Was ist Dein Freunde Deal?
        </BoldText>
        <br/>
        <RegularText>
          Als Online-Vorteilsplattform wollen wir die Freizeit- und Kulturszene neu beleben, Menschen zusammenbringen und dafür die richtigen Anreize bieten. Du kannst dich kostenlos registrieren und einen passenden Pass erwerben, mit welchem du Zugriff auf tolle Deals bekommst.
        </RegularText>
        { DataS.FAQ_QUESTIONS.map((category: Category, index: number) => {
          return (
            <div key={index} style={{ marginTop: 50, marginBottom: 0 }}>
              <Stack alignItems={'center'} direction='row' justifyContent={'center'}>
                <QuizRoundedIcon sx={{ fontSize: 48, paddingRight: 1 }}/>
                <MediumTitle align="center">
                  { category.label }
                </MediumTitle>
              </Stack>
              <Grid container spacing={5} mb={10} mt={0}>
                { category.questions.map((question: Question, index: number) => {
                  return (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                      <BoldText upperCase>
                        { question.question }
                      </BoldText>
                      <br/>
                      <RegularText>
                        { question.answer }
                      </RegularText>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          );
        })}
      </Container>
      <Footer />
    </>
  );
}

export default FaqPage;
