import { useContext } from "react";
import GoogleMapReact from "google-map-react";
import MapMarker from "./MapMarker";
import { MapS } from "../../services/MapS";
import { DealS } from "../../services/DealS";
import { LocationContext } from "../../contexts/LocationContext";
import ModeStandbyRoundedIcon from "@mui/icons-material/ModeStandbyRounded";
import { DealsContext } from "../../contexts/DealsContext";

const GoogleMapWrapper = ({ deals, mapCenter, mapZoom, markerSize }) => {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const { location } = useContext(LocationContext);
  const { updateFilter, setShowMapPreview } = useContext(DealsContext);

  if (!API_KEY) {
    console.error("Unable to display map preview. API key do not exists");
    return null;
  }
  const partnersDeals = DealS.groupDealsByPartner(deals);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: API_KEY }}
      defaultCenter={MapS.CITIES.Stuttgart}
      center={mapCenter}
      defaultZoom={mapZoom || 14}
    >
      {partnersDeals.map((partnerDeals, index) => {
        const { lat, lng, partnerAlias } = partnerDeals.deals[0];
        return (
          <MapMarker
            key={index}
            onClick={() => {
              updateFilter({ partnerAlias });
              setShowMapPreview(false);
            }}
            lat={lat}
            lng={lng}
          />
        );
      })}
      {location && (
        <ModeStandbyRoundedIcon
          sx={{
            fontSize: markerSize / 2,
            color: "#2962ff",
            transform: "translate(-50%, -50%)",
          }}
          lat={location.latitude}
          lng={location.longitude}
        />
      )}
    </GoogleMapReact>
  );
};

export default GoogleMapWrapper;
