import React, {useContext} from "react";
import {MediumText, MediumTitle} from "../../atoms/Typography";
import {WindowContext} from "../../contexts/WindowContext";
import {Image, ImageWithLabel} from "../../atoms/Image";
import {AbsoluteBox, RelativeBox} from "../../atoms/layout/Boxes";
import styled from "styled-components";
import Panel from "../../atoms/layout/Panel";
import {Stack} from "@mui/material";

const LandingImageBox = styled.div`
  clip-path: circle(76.5% at 51% 20%);
  top: -50px;
  width: 100%;
  margin-top: -150px;
`;

const Introduction: React.FC<{}> = () => {
  const { isPhoneDevice } = useContext(WindowContext);

  return (
    <>  
      <LandingImageBox>
        <Image src={'img/landing-image.png'}/>
      </LandingImageBox>
        {/*<Stack direction={'row'} justifyContent={'flex-end'}>
            <div style={{marginTop: -125}}>
                <ImageWithLabel small
                                label='Freizeit'
                                position='20%'
                                imgProps={{
                                    src: 'img/categories/culture.jpg',
                                    width: 120,
                                    height: 120,
                                    rounded: true,
                                    withBorder: true
                                }}
                />
            </div>
        </Stack>*/}

        {/*<RelativeBox style={{height: 150, marginTop: -40}}>
            <AbsoluteBox style={{left: 0, bottom: 20}}>
                <ImageWithLabel small label='Restaurants'
                                position="20%"
                                imgProps={{
                                    src: 'img/categories/restaurants.jpg',
                                    width: 130,
                                    height: 130,
                                    rounded: true
                                }}
                />
            </AbsoluteBox>
            <AbsoluteBox style={{right: -10, top: -10}}>
                <ImageWithLabel small label='Nightlife'
                                imgProps={{
                                    src: 'img/categories/nightlife.jpg',
                                    width: 130,
                                    height: 130,
                                    rounded: true
                                }}
                />
            </AbsoluteBox>
            <AbsoluteBox style={{right: 90, bottom: 5}}>
                <ImageWithLabel small label='Café'
                                position="5%"
                                imgProps={{
                                    src: 'img/categories/caffe.jpg',
                                    width: 100,
                                    height: 100,
                                    rounded: true,
                                    withBorder: true
                                }}
                />
            </AbsoluteBox>
        </RelativeBox>*/}
      <br/>
    </>
  );
}

export default Introduction;
