import { HttpS } from "./HttpS";

const ENTITIES = {
  PHOTO: 'photos',
  DEAL: 'deals',
  PUBLIC: 'public'
}

type FileDTO = {
  fileName: string,
  fileSize: null,
  lastModified: Date
}

const getDealImageUrl = (dealId: string, partnerId: string, fileName: string): string =>  {
  return `${process.env.REACT_APP_FILE_SERVICE_URL}/files/deals/${dealId}/${fileName}?profileId=${partnerId}`;
}

const getFilesPerDeal = (partnerId: string, dealId: string): Promise<FileDTO[] | undefined> => {
  return HttpS.get(
    `${process.env.REACT_APP_FILE_SERVICE_URL}/files/deals/${dealId}?profileId=${partnerId}`
  ).then(resp => {
    if (resp.ok) {
      return resp.json();
    }
  })
}

const fetchFile = (entityType: string, entityId: string | null, fileName: string): Promise<Blob | undefined> =>  {
  return HttpS.get(
    `${process.env.REACT_APP_FILE_SERVICE_URL}/files/${entityType}/${entityId ? entityId : 'default'}/${fileName}`
  ).then((resp: Response) => {
    if (resp.ok) {
      return resp.blob();
    }
  });
}

const uploadFile = (entityType: string, entityId: string | null, file: File): Promise<boolean> => {
  return HttpS.putFile(
    `${process.env.REACT_APP_FILE_SERVICE_URL}/files/${entityType}/${entityId ? entityId : 'default'}`,
    file
  ).then((resp: Response) => resp.ok)
  .catch((err: Error) => {
    console.log(err);
    return false;
  });
}

export const FileS = {
  ENTITIES,
  getFilesPerDeal,
  getDealImageUrl,
  uploadFile,
  fetchFile
}