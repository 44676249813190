import React from "react";
import ModeEditRoundedIcon  from '@mui/icons-material/ModeEditRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import IconWrapper from "../../atoms/IconWrapper";
import { Box, List, ListItem, ListItemIcon, Drawer } from "@mui/material";
import { LocalStorageS } from "../../services/LocalStorageS";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { MenuItem } from "../../atoms/Typography";

const UserActionsPopup: React.FC<{
  isOpen: boolean
  onClose: () => void,
  showPass: () => void,
}> = ({
  isOpen,
  onClose,
  showPass
}) => {
  const navigate: NavigateFunction = useNavigate();

  return (
    <Drawer
      anchor='top'
      open={isOpen}
      onClose={onClose}
      style={{ zIndex: 10 }}
    >    
        <Box ml={4} mt={11} mr={5}>
          <List dense>
            <ListItem onClick={() => navigate('/change-password')}>
              <ListItemIcon>
                <IconWrapper>
                  <ModeEditRoundedIcon />
                </IconWrapper>
              </ListItemIcon>
              <MenuItem>
                Passwort ändern
              </MenuItem>
            </ListItem>
            <ListItem onClick={() => {
              showPass();
              onClose();
            }}>
              <ListItemIcon>
                <IconWrapper>
                  <PersonRoundedIcon />
                </IconWrapper>
              </ListItemIcon>
              <MenuItem>
                Mein Pass
              </MenuItem>
            </ListItem>
            <ListItem onClick={() => {
              LocalStorageS.deleteJWT();
              onClose();
            }}>
              <ListItemIcon>
                <IconWrapper>
                  <ExitToAppRoundedIcon/>
                </IconWrapper>
              </ListItemIcon>
              <MenuItem>
                Abmelden
              </MenuItem>
            </ListItem>
          </List>
        </Box>
      <br/>
    </Drawer>
  );
}

export default UserActionsPopup;