import React from "react";
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';

const MapMarker: React.FC<{
  onClick: () => void,
}> = ({ 
  onClick
}) => {
  return (
      <FiberManualRecordRoundedIcon 
        onClick={onClick}
        sx={{ 
          fontSize: 32,
          color: 'red',
          transform: 'translate(-50%, -50%)'
        }} 
      />
  );
}

export default MapMarker;