import React, { useState, useContext } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Header from "../components/layout/Header";
import { PageTitle } from "../atoms/Typography";
import Panel from "../atoms/layout/Panel";
import { TextField } from "../atoms/inputs/TextField";
import Button from "../atoms/inputs/Button";
import { AuthS } from "../services/AuthS";
import PageContainer from "../atoms/layout/PageContainer";
import { WindowContext } from "../contexts/WindowContext";
import { AlertS, AlertType } from "../services/AlertS";
import { Alert } from "@mui/material";
import BackdropLoader from "../components/backdropLoader/BackdropLoader";
import PasswordChecker from "../components/passwordChecker/PasswordChecker";
import { LocalStorageS } from "../services/LocalStorageS";

const ChangePasswordPage: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const { isPhoneDevice } = useContext(WindowContext);
  const [oldPassword, setOldPassword] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [repeatedPassword, setRepeatedPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertType | null>();
  const [isPasswordValid, setValidPassword] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const changePasswordHandler = () => {
    if (validateForm()) {
      setLoading(true);
      AuthS.changePassword({ oldPassword, newPassword: password }).then((success: boolean) => {
        if (success) {
          LocalStorageS.deleteJWT();
          navigate(`/login?event=${AlertS.Event.PASSWORD_CHANGED}`);
        } else {
          setAlert(AlertS.Alert.INVALID_OLD_PASSWORD);
        }
      }).catch(() => {
        setAlert(AlertS.Alert.SERVER_UNAVAILABLE);
      }).finally(() => {
        setLoading(false);
      });
    } else {
      setShowErrors(true);
    }
  }

  const validateForm = (): boolean => {
    return isPasswordValid && password === repeatedPassword;
  }

  return (
    <>
      <BackdropLoader isOpen={loading}/>
      <Header />
      <PageContainer>
        <Panel>
          <PageTitle small={isPhoneDevice}>
            PASSWORT
          </PageTitle>
        </Panel>
        {alert && (
          <Alert severity={alert.severity} variant='filled'>
            {alert.message}
          </Alert>  
        )}
          <Panel>
            <TextField 
              value={oldPassword} 
              onChange={setOldPassword}
              label={'Altes Passwort'}
              type='password'
            />
          </Panel>
          <Panel>
            <TextField 
              value={password} 
              onChange={setPassword}
              label={'Neues Passwort'}
              type='password'
              error={!isPasswordValid && showErrors}
              errorMessage={'Das Passwort entspricht nicht den Richtlinien bedingungen.'}
            />
          </Panel>
          <PasswordChecker 
            password={password} 
            showErrors={showErrors}
            setValidPassword={setValidPassword}
          />
          <Panel>
            <TextField 
              value={repeatedPassword} 
              onChange={setRepeatedPassword}
              label={'Neues Passwort Wiederholung'}
              type='password'
              error={password !== repeatedPassword && showErrors}
              errorMessage={'Passwörter stimmen nicht überein.'}
            />
          </Panel>
          <Panel>
            <Button
              label={'PASSWORT ÄNDERN'}
              onClick={changePasswordHandler}
              maxWidth={'350px'}
              withMargin
            />
          </Panel>
      </PageContainer>
    </>
  );
}

export default ChangePasswordPage;