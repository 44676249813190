import React, {useContext, useEffect, useState} from "react";
import { FileS } from "../../services/FileS";
import { Grid } from '@mui/material';
import { Title, RegularText, ItalicText } from "../../atoms/Typography";
import DistancePreview from "../distancePreview/DistancePreview";
import { Deal } from "../../services/DealS";
import TimeFrame from "../timeFrame/TimeFrame";
import { WindowContext } from '../../contexts/WindowContext';
import DealImage from "./DealImage";

const DealPreview: React.FC<{
  deal: Deal,
  dealSelected: boolean, 
  onClick: () => void,
  direction?: string,
  withBorder?: boolean,
  imgSize?: number
}> = ({ 
    deal,
    dealSelected,
    onClick,
    direction,
    withBorder,
    imgSize
}) => {
    const fallbackImage = 'img/deal-placeholder.png';
    const { isDesktopDevice } =  useContext(WindowContext);
    const [dealImage, setDealImage] = useState(fallbackImage);
    const { byAppointment } = deal;

    useEffect(() => {
      FileS.getFilesPerDeal(deal.partnerId, deal.id).then(files => {
        if (files && files.length > 0) {
          const imageSrc = FileS.getDealImageUrl(deal.id, deal.partnerId, files[0].fileName);
          setDealImage(imageSrc);
          deal.image = imageSrc;
        }        
      }).catch(err => {
        console.log(err);
      });
    }, [deal])

  return (
    <Grid container
      direction={direction || isDesktopDevice ? 'column' : 'row'}
      alignItems="center"
      justifyContent="center"
      onClick={onClick}
      style={{ cursor: 'pointer', marginBottom: 40 }}
      spacing={isDesktopDevice ? 0 : 3}
    >
      <Grid item>
        <DealImage
          deal={deal}
          size={imgSize || 140}
          selected={dealSelected}
          src={dealImage}
          withBorder={withBorder}
        />
      </Grid>
      <Grid item style={{ 
          textAlign: (isDesktopDevice || direction === 'column' ) ? 'center' : 'left', 
          marginTop: isDesktopDevice ? 10 : 0, 
          width: 200
        }}>
        <Title>
          {deal.title}
        </Title>
        <RegularText>
          {deal.partnerAlias}    
        </RegularText>
        <ItalicText>
        <ItalicText>
          {byAppointment && "nach Vereinbarung"}
          {!byAppointment &&
            deal.timeFrames.map((timeFrame: any, index: number) => {
              return <TimeFrame key={index} timeFrame={timeFrame} />;
            })}
          </ItalicText>
        </ItalicText>
        <DistancePreview deal={deal} center={direction === 'column'}/>
      </Grid>
    </Grid>
  )
}

export default DealPreview;
