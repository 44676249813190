import React, { useContext, useState } from "react";
import { Box, Stack } from "@mui/material";
import CarouselWrapper from '../carousel/CarouselWrapper';
import Plan from "./Plan";
import { PassPlan, PassS } from "../../services/PassS";
import { WindowContext } from "../../contexts/WindowContext";
import { PassBackgroundBox, RelativeBox } from "../../atoms/layout/Boxes";
import { MediumText, MediumTitle } from "../../atoms/Typography";
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import Panel from "../../atoms/layout/Panel";
import { NavigateFunction, useNavigate } from "react-router-dom";
import IconWrapper from "../../atoms/IconWrapper";

const PassPreview: React.FC<{showTitle?: boolean}> = ({ showTitle }) => {
  const { isPhoneDevice, isDesktopDevice } = useContext(WindowContext);
  const navigate: NavigateFunction = useNavigate();
  const [selectedPass, selectPass] = useState<PassPlan>(PassS.PASS[0]);
  const onChangeHandler = (index: number) => selectPass(PassS.PASS[index]);

  return (
    <Box>
    { showTitle && (
      <Panel>
        <MediumTitle large={!isPhoneDevice}>
          DEINE OPTIONEN
        </MediumTitle>
        <MediumText small={isPhoneDevice}>
          Wähle deinen Pass nach individuellem Bedarf
        </MediumText>
      </Panel>
    )}
      <RelativeBox style={{ height: 500, marginTop: 50 }}>
        <PassBackgroundBox />
        { isDesktopDevice ? (
          <Stack direction={'row'} spacing={20} justifyContent='center'>
            { PassS.PASS.map((pass: PassPlan, index: number) =>
              <Plan 
                key={index} 
                pass={pass} 
              />
            )}
          </Stack>
        ) : (
          <>
            <CarouselWrapper height={'375px'} onChange={onChangeHandler} dotColor={'#f5f5f5'} >
            { PassS.PASS.map((pass: PassPlan, index: number) =>
              <Plan 
                key={index} 
                pass={pass} 
              />
            )}
            </CarouselWrapper> 
            <br/>
            <br/>
            <IconWrapper big={!isPhoneDevice}>
              <ShoppingCartRoundedIcon 
                sx={{ fontSize: isPhoneDevice? 25 : 30 }}
                onClick={ () => navigate(`/shopping-cart?pass=${selectedPass.label}`) }
              />
            </IconWrapper>
          </>
        )}
      </RelativeBox>
    </Box>
  );
}

export default PassPreview;
