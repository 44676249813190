import Panel from "../../atoms/layout/Panel";
import {MediumText, MediumTitle} from "../../atoms/Typography";
import React, {useContext} from "react";
import {WindowContext} from "../../contexts/WindowContext";

const IntroductionTitlePhone = () => {
    const { isPhoneDevice } = useContext(WindowContext);

    return (
        <Panel>
            <MediumTitle large={!isPhoneDevice}>
                Gemeinsam Deals entdecken
            </MediumTitle>
            <MediumText small={isPhoneDevice}>
                und Freundschaften
            </MediumText>
            <MediumText small={isPhoneDevice}>
                pflegen
            </MediumText>
        </Panel>
    )
}

export default IntroductionTitlePhone;