import React, { useContext } from 'react';
import { Image, ImageWithLabel } from '../../atoms/Image';
import { AbsoluteBox, RelativeBox } from '../../atoms/layout/Boxes';
import { MediumText, PageTitle } from '../../atoms/Typography';
import { WindowContext } from "../../contexts/WindowContext";
import { useWindowDimensions } from '../../hooks/DimensionsHook';
import Paragraph from './Paragraph';
import { DataS } from '../../services/DataS';
import VideoBox from '../videoBox/VideoBox';

const IntroductionDesktop = () => {
  const { isDesktopDevice } = useContext(WindowContext);
  const { width } = useWindowDimensions();
  const boxSize = width * .6;

  return (
    <>
      <RelativeBox height={boxSize * .6  + 'px'}>
        <AbsoluteBox top={-boxSize * .65 + 'px'}  style={{overflow: 'hidden', width: "100%"}}>
          <Image src={'img/landing-image.png'} height={width * .7} rounded/>
        </AbsoluteBox>

        <AbsoluteBox right='0' bottom={boxSize * .2 + 'px'}>
          <ImageWithLabel
            label='Freizeit'
            position='20%'
            imgProps={{ 
              src: 'img/categories/culture.jpg',
              height: boxSize * .3,
              width: boxSize * .3,
              rounded: true,
              withBorder: true,
              borderWidth: isDesktopDevice? 10 : 7
            }}  
          />
        </AbsoluteBox>

        <AbsoluteBox right={boxSize * .25 + 'px'} bottom={boxSize * .1 + 'px'}>
          <ImageWithLabel
            label='Restaurants' 
            position="20%"
            imgProps={{ 
              src: 'img/categories/restaurants.jpg',
              height: boxSize * .275,
              width: boxSize * .275,
              rounded: true,
              withBorder: true,
              borderWidth: isDesktopDevice? 10 : 7
            }}  
          />
        </AbsoluteBox>
      </RelativeBox>

    </>
  );

}


export default IntroductionDesktop;