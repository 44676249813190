import React, { useState, useContext, useEffect } from "react";
import { Drawer, Dialog, DialogContent, Stack, Box, Slide, Grid } from "@mui/material";
import { ToggleChip } from "../../atoms/shapes/ToggleChip";
import { BolderText, MediumTitle, SmallerText } from "../../atoms/Typography";
import Panel from "../../atoms/layout/Panel";
import ShareButtons from "../shareButtons/ShareButtons";
import { Deal } from "../../services/DealS";
import { DataS } from "../../services/DataS";
import { WindowContext } from "../../contexts/WindowContext";
import { PostBox,  ScrollBox } from '../../atoms/layout/Boxes';
import Button from "../../atoms/inputs/Button";
import DealImage from "../deal/DealImage";
import CarouselWrapper from "../carousel/CarouselWrapper";

const SharePopup: React.FC<{
  isOpen: boolean,
  onClose: () => void,
  deal: Deal,
}> = ({
  isOpen,
  onClose,
  deal
}) => {
  const { isPhoneDevice } = useContext(WindowContext);

  return (
    <>
      { isPhoneDevice ? (
        <Drawer
          anchor='bottom'
          open={isOpen}
          onClose={onClose}
          style={{ zIndex: 1300 }}
          PaperProps={{
            sx: {
              backgroundImage: 'linear-gradient(to bottom, #f0f0f8, #ffffff, #f0f0f8, #f0f0f8, #ebebf4, #f0f0f8, #ffffff)'
            }
          }}
        >
          <DealShare deal={deal} />
          <Panel>
            <Button
              label='Abbrechen'
              onClick={onClose}
              color='secondary'
              width="150px"
              withMargin
            />
          </Panel>
        </Drawer>
      ) : (
        <Dialog 
          fullScreen={isPhoneDevice}
          open={isOpen}
          onClose={onClose}
          TransitionComponent={Slide}
          style={{
            margin: 10,
          }}
          PaperProps={{
            style: { borderRadius: 25, overflowX: 'hidden' }
          }}
        >
          <DialogContent  style={{
            width: '500px',
            minHeight: 550,
            padding: 0,
            backgroundImage: 'linear-gradient(to bottom, #f0f0f8, #ffffff, #f0f0f8, #f0f0f8, #ebebf4, #f0f0f8, #ffffff)',
            position: 'relative'
          }}>
            <DealShare deal={deal} />
            <Panel>
              <Button
                label='Abbrechen'
                onClick={onClose}
                color='secondary'
                width={'150px'}
                withMargin
              />
            </Panel>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

const DealShare: React.FC<{deal: Deal}> = ({ deal }) => {
  const { isDesktopDevice } = useContext(WindowContext);
  const [selectedCategory, selectCategory] = useState<number>(0);
  const [contentMessage, setContentMessage] = useState<string>('');
  const [index, setIndex] = useState<number>(0);
  
  useEffect(() => {
    setContentMessage(
      DataS.SHARED_MESSAGES[selectedCategory].messages[0]
    );
    setIndex(0);
  }, [selectedCategory]);

  const swipe = (index: number) => {
    setIndex(index);
    setContentMessage(
      DataS.SHARED_MESSAGES[selectedCategory].messages[index]
    );
  }


  return (
    <Box mt={2} ml={2} mr={2}>
      <Panel>
        <MediumTitle>
          DEAL TEILEN
        </MediumTitle>
      </Panel>
      <Grid container spacing={1} justifyContent='center'>
        { DataS.SHARED_MESSAGES.map((sharedMessages, index) => {
          return (
            <Grid item>
              <ToggleChip
                key={index}
                label={sharedMessages.category}
                isSelected={selectedCategory === index}
                onSelect={() => selectCategory(index)}
              />
              </Grid>
        )})}
      </Grid>
      <Box mt={4} mb={4}>
        <PostBox>
            <Stack direction={'row'} spacing={2}>
              <DealImage
                hideDiscountBadge
                deal={deal}
                size={48}
              />
              <div>
                <BolderText>
                  {deal.title} 
                </BolderText>
                <SmallerText>
                  {deal.partnerAlias} 
                </SmallerText>
                </div>
              </Stack>
                <br/>
                <CarouselWrapper swipeable={!isDesktopDevice} height={100} onChange={swipe} index={index}>
                  { DataS.SHARED_MESSAGES[selectedCategory].messages.map((message, index) => {
                    return (
                      <SmallerText key={index} align='left' style={{height: 120, paddingLeft: 15}}>
                        { message }
                      </SmallerText>
                    );
                  })}
                </CarouselWrapper>
            </PostBox>
      </Box>
        <ScrollBox>
          <ShareButtons 
            deal={deal}
            description={contentMessage}
          />
        </ScrollBox>
    </Box>
  );
};

export default SharePopup;