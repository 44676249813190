import styled from "styled-components";

interface ButtonProps {
  label: string,
  onClick: () => void,
  color?: string,
  width?: string,
  maxWidth?: string,
  minWidth?: string,
  withMargin?: boolean,
  disabled?: boolean,
}

const StyledButton = styled.button<ButtonProps>`
  background-color: ${props => props.color === 'secondary' ? '#ebebf4' : '#edc662' };
  background-image: ${props => props.color !== 'secondary' ? 'linear-gradient(to right, #ffa100, #ffd600)' : ''};
  border-radius: 25px;
  font-weight: 500;
  width: ${props => props.width ? props.width : '100%' };
  padding: 10px 0;
  text-transform: uppercase;
  max-width: ${props => props.maxWidth};
  min-width: ${props => props.minWidth};
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  margin:  ${props => props.withMargin ? '2em auto 2em auto' : 0};
  text-shadow: 0px 0px 6px rgba(255,255,255,0.7);
  color: #212121;
  cursor: pointer;
  border: 0;
  opacity: ${props => props.disabled? .5 : 1}
`;

const Button = (props : ButtonProps) => {
  return (
    <StyledButton {...props}>
      { props.label }
    </StyledButton>
  );
}

export default Button;