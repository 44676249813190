const getDistanceFromLatLonInKm = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km

  return Number(d).toFixed(2);
}

const deg2rad = (deg: number) => {
  return deg * (Math.PI/180)
}

export type GeoPoint = {
  lat: number,
  lng: number
}

interface CityLocation {
  [name: string]: GeoPoint
}

const CITIES: CityLocation = {
  'Waiblingen': {
    lat: 48.830278,
    lng: 9.316944
  },
  'Ludwigsburg': {
    lat: 48.894062,
    lng: 9.195464  
  },
  'Stuttgart': {
    lat: 48.775846,
    lng: 9.182932
  }
}

export const MapS = {
  CITIES,
  getDistanceFromLatLonInKm,
}